import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner";
import bannerimage from "../../assets/bg-6.png";
import roundDots from "../../assets/fullCycle.png";
import renderHTML from "react-render-html";
import DayJS from "react-dayjs";
import RelPost from "./RelatedPost";
import Aos from "aos";
import "aos/dist/aos.css";
export default class News extends Component {
  state = {
    postDetails: [],
    media: [],
    category: [],
    author: [],
    related: [],
    AllIDs: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    Aos.init({
      duration: 1200,
    });
    axios
      .get(`https://wp.logilab.world/?rest_route=/wp/v2/posts&per_page=3&_embed`)
      .then((response) => {
        this.setState({ AllIDs: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        `https://wp.logilab.world/?rest_route=/wp/v2/posts/${this.props.match.params.id}`
      )
      .then((res) => {
        axios
          .get(
            `https://wp.logilab.world/?rest_route=/wp/v2/media/${res.data.featured_media}`
          )
          .then((response) => {
            this.setState({ media: response.data.guid.rendered });
          })
          .catch((response) => {
            console.log(response.err);
          });
        axios
          .get(
            `https://wp.logilab.world/?rest_route=/wp/v2/categories&post=${res.data.id}`
          )
          .then((resp) => {
            this.setState({ category: resp.data["0"] });
          })
          .catch((response) => {
            console.log(response.err);
          });
        axios
          .get(
            `https://wp.logilab.world/?rest_route=/wp/v2/users/${res.data.author}`
          )
          .then((resp) => {
            this.setState({ author: resp.data });
          })
          .catch((response) => {
            console.log(response.err);
          });

        this.setState({
          postDetails: res.data,
        });
      });
  }

  render() {
    const { postDetails, media, category, author } = this.state;
    return (
      <React.Fragment>
        <BannerSection>
          <Container>
            <h2>NEWS</h2>
            <img src={roundDots} alt="Logilab" />
          </Container>
        </BannerSection>
        {postDetails.length === 0 || postDetails === undefined ? (
          <div style={{ marginBottom: "15em", marginTop: "15em" }}>
            <Spinner />
          </div>
        ) : (
          <Content>
            {media.length !== 0 && (
              <ImageHead src={media} alt="Featured Image" />
            )}

            <ImageSub>
              <DisplayLeft>
                <p>
                  <StyledFontAwesome icon={faCalendarWeek} />
                  <DayJS format="YYYY-MM-DD">{postDetails.date}</DayJS>
                </p>

                <p>
                  <StyledFontAwesome icon={faUserCircle} />
                  {author.name}
                </p>
              </DisplayLeft>
              <DisplayRight>
                <EventsBtn>{category.name}</EventsBtn>
              </DisplayRight>
            </ImageSub>
            <ArticleHead>{renderHTML(postDetails.title.rendered)}</ArticleHead>

            <ArticleBody>
              {renderHTML(postDetails.content.rendered)}
            </ArticleBody>
          </Content>
        )}
        <Related>
          <Divider
            data-aos="slide-right"
            style={{ transitionDuration: "2.4s" }}
          />
          { /* Note: Unable to retrieve related articles from the API(yarpp) Change related articles to recommended articles */}
          <h2>Recommended Articles</h2>
          <RelContent>
            {this.state.AllIDs.map((item) => (
              <RelPost key={item.id} post={item} />
            ))}
          </RelContent>
        </Related>
      </React.Fragment>
    );
  }
}

const BannerSection = styled.div`
  @media (min-width: 576.1px) {
    background: url(${bannerimage});
    background-size: cover;
    height: 220px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 576px) {
    background: url(${bannerimage});
    background-size: cover;
    height: 220px;
    display: flex;
    align-items: center;
  }
`;
const Container = styled.div`
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 1;
  @media (min-width: 1200px) {
    width: 1150px;
    margin: auto;
  }
  img {
    position: absolute;
    top: 105px;
    left: 82px;
    z-index: 3;
  }
  h2 {
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
    padding-top: 70px;
  }
  @media (max-width: 1199px) {
    width: 95%;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding-left: 0px;
    img {
      display: none;
    }
  }
`;
const Content = styled.div`
  width: 550px;
  margin: auto;
  padding-bottom: 40px;

  @media (min-width: 1200px) {
    width: 550px;
    margin: auto;
  }

  @media only screen and (max-width: 768.9px) and (min-width: 576px) {
    width: 85%;
    margin: auto;
  }
  @media (max-width: 575.9px) {
    width: 100%;
    margin: auto;
  }
`;
const ImageHead = styled.img`
  width: 100%;
  margin-top: 52px;

  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
const ImageSub = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  @media (max-width: 576px) {
    flex-direction: column;
    width: 297px;
    margin: auto;
  }
`;
const DisplayLeft = styled.div`
  display: flex;

  p:first-child {
    padding-right: 20px;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 0px;
  }
  p:last-child {
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 0px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledFontAwesome = styled(FontAwesomeIcon)`
  font-size: 24px;
  padding-right: 10px;
  vertical-align: middle;
  margin-top: -3px;
  @media (max-width: 375px) {
    padding-right: 5px;
    font-size: 20px;
  }
`;
const DisplayRight = styled.div`
  display: flex;
  margin-left: auto;
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
`;
const EventsBtn = styled.div`
  color: black;
  border: 1px solid black;
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
  padding: 0px 12px;
`;
const ArticleHead = styled.h3`
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
  font-style: normal;
  padding-top: 20px;

  @media (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    width: 292px;
    margin: auto;
  }
`;
const ArticleBody = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  img {
    width: 100%;
    height: 100% !important;
  }

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
    width: 297px;
    margin: auto;
  }
`;

const Related = styled.div`
  @media (min-width: 1200px) {
    width: 1100px;
    margin: auto;
    margin-top: 90px;
    margin-bottom: 63px;
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-top: 19px;
    margin-bottom: 19px;
  }
  @media (max-width: 1199px) {
    width: 980px;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 550px;
    margin: auto;
  }
  @media only screen and (max-width: 768.9px) and (min-width: 576px) {
    width: 85%;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 297px;
    margin: auto;
  }
`;
const Divider = styled.div`
  height: 22px;
  background-color: var(--pink);
  width: 268px;
`;
const RelContent = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 1200px) {
    width: 100%;
    margin: auto;
    justify-content: space-between;
  }
  @media (max-width: 991px) {
    width: 550px;
    margin: auto;
    flex-direction: column;
  }
  @media (max-width: 767.9px) {
    width: 100%;
  }
`;
