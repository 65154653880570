import React, { Component } from "react";
import { AppConsumer } from "../../Context";
import Spinner from "../Spinner";
import Post from "./Post";
import Pagination from "react-js-pagination";
import axios from "axios";
import styled from "styled-components";

export default class PostList extends Component {
  constructor(props) {
    super();
    this.state = {
      activePage: 1,
      postTitle: "",
      result: [],
      activeSearchPage: 1,
      searchPostNumber: 0,
      loading: false,
      searching: false,
    };
  }

  onChange = (e) => {
    const value = e.target.value;
    this.setState({ [e.target.name]: value });
    if (value === "") {
      this.setState({
        result: [],
        searching: false,
        loading: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleSearchPage = (pageNumber) => {
    axios
      .get(
        `https://wp.logilab.world/?rest_route=/wp/v2/posts&search=${this.state.postTitle}&_embed&page=${pageNumber}&per_page=12`
      )
      .then((res) => {
        console.log(res);
        this.setState({
          result: res.data,
          activeSearchPage: pageNumber,
        });
      })
      .catch((res) => {
        console.log(res);
      });
  };

  findPost = (e) => {
    this.setState({ searching: true, loading: true});
    e.preventDefault();
    axios
      .get(
        `https://wp.logilab.world/?rest_route=/wp/v2/posts&search=${this.state.postTitle}&per_page=12&_embed`,
        {},
      )
      .then((res) => {
        this.setState({
          result: res.data,
          loading: false,
          searchPages: res.headers["x-wp-totalpages"],
          searchPostNumber: res.headers["x-wp-total"],
        });
      })
      .catch((error) => {
        console.error('Error searching videos', error);
        this.setState({ searching: false, loading: false });
      });
  };
  handlePageChange = (pageNumber, pagedispatch) => {
    pagedispatch({
      type: "SET_NEW_PAGE",
      payload: pageNumber,
    });
    this.setState({
      activePage: pageNumber,
    });
  };

  render() {
    const NotFound = "Post Not Found";
    return (
      <React.Fragment>
        <SearchWrapper>
          <SearchContainer>
            <SearchTitle>
              <p>Search here </p>
            </SearchTitle>
            <StyledForm onSubmit={this.findPost}>
              <SearchFlex>
                <StyledInput
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Search"
                  id="searchbutton"
                  name="postTitle"
                  value={this.state.postTitle}
                  onChange={this.onChange}
                  autocomplete="off"
                />
              </SearchFlex>
            </StyledForm>
          </SearchContainer>
        </SearchWrapper>

        <Content>
          {!this.state.searching ? (
            <AppConsumer>
              {(value) => {
                const {
                  AllPost,
                  NumberOfPosts,
                  pagedispatch,
                  loadingPosts,
                } = value;

                if (
                  loadingPosts ||
                  AllPost === undefined ||
                  AllPost.length === 0
                ) {
                  return <Spinner />;
                } else {
                  return (
                    <React.Fragment>
                      <Container>
                        {AllPost.AllPost.map((item) => (
                          <Post key={item.id} post={item} />
                        ))}
                      </Container>
                      <PaginationWrapper>
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={12}
                          totalItemsCount={Number(NumberOfPosts)}
                          pageRangeDisplayed={3}
                          onChange={(page) => {
                            this.handlePageChange(page, pagedispatch);
                            this.scrollToTop();
                          }}
                        />
                      </PaginationWrapper>
                    </React.Fragment>
                  );
                }
              }}
            </AppConsumer>
          ) : this.state.loading ? <Spinner /> : (!this.state.loading && this.state.result.length === 0) ? (
            <h3 style={{margin: "24px 20%"}}>{NotFound}</h3>
          ) : (
            <React.Fragment>
              <Container>
                {this.state.result.map((item) => (
                  <Post key={item.id} post={item} />
                ))}
              </Container>
              <PaginationWrapper>
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={Number(this.state.activeSearchPage)}
                  itemsCountPerPage={12}
                  totalItemsCount={Number(this.state.searchPostNumber)}
                  pageRangeDisplayed={3}
                  onChange={(pageNumber) => {
                    this.handleSearchPage(pageNumber);
                    this.scrollToTop();
                  }}
                />
              </PaginationWrapper>
              </React.Fragment>
          )}
        </Content>
      </React.Fragment>
    );
  }
}

const SearchWrapper = styled.div`
  background-color: var(--pink);
  height: 90px;
  width: 100%;
  margin-bottom: 60px;
`;
const SearchContainer = styled.div`
  width: 90%;
  margin: auto;
  height: 90px;
  display: flex;
  align-items: center;
  @media (min-width: 1200px) {
    width: 1200px;
    padding-left: 20px;
    padding-right: 77px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 30px;
  }
`;
const SearchTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  svg {
    width: 15px;
    height: 15px;
    color: white;
    margin-left: 10px;
    font-size: 16px;
  }
`;
const SearchFlex = styled.div`
  display: flex;
  padding-right: 12px;
  border-radius: 10px;
  margin: auto;
  @media (max-width: 426px) {
    margin-left: auto;
  }
`;
const StyledInput = styled.input`
  width: 523px;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  border-color: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  :focus {
    box-shadow: none !important;
    border: none;
    border-color: transparent;
  }
  ::placeholder {
    color: #c4c4c4;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 20px;
  }
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 153px;
  }

  @media (max-width: 425px) {
    width: 153px;
    margin: auto;
    font-size: 12px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  width: 523px;
  margin-left: auto;
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 153px;
  }
  @media (max-width: 426px) {
    width: 153px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  color: var(--darkGray);
  justify-content: center;
  margin-top: 19px;
  margin-bottom: 133px;
  .active {
    a {
      background-color: white !important;
      color: var(--mainOrange) !important;
      border-color: 1px solid var(--mainOrange) !important;
    }
  }

  a {
    color: var(--darkGray) !important;
    background-color: white;
    border-color: 1px solid var(--darkGray) !important;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 33px;
    // height: 33px;
    padding: 5px 12px;
    border-radius: 4px;
    justify-content: center;
    font-size: 14px;
    line-height: 22px;
  }
  .page-item-disabled {
    display: none;
  }

  .page-item:last-child .page-link {
    display: none;
  }
  .page-item:first-child .page-link {
    display: none;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Container = styled.div`
  width: 1050px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  @media (max-width: 1199px) {
    width: 950px;
    margin: auto;
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    width: 700px;
    margin: auto;
    flex-wrap: wrap;
    justif-content: space-around;
  }
  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const Content = styled.div`
  @media (min-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 1199.9px) and (min-width: 769px) {
    width: 100%;
  }
  @media only screen and (max-width: 768.9px) and (min-width: 375px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin: auto;
  }
  @media (max-width: 426px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin: auto;
  }
`;
