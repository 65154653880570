import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Navbar, Nav, NavItem } from "reactstrap";
import Logo from "../assets/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import animateScrollTo from "animated-scroll-to";

const useScrollTo = (selector) => {
  return useCallback(() => {
    const element = selector ? document.querySelector(selector) : 0;
    animateScrollTo(element);
  }, [selector]);
};

const useBoxShadow = (location) => {
  const [boxshadow, setBoxShadow] = useState("transparent");

  const handleScroll = useCallback(() => {
    const yOffset = window.pageYOffset;
    const isHome = location.pathname === "/";
    const isNews = location.pathname.split("/")[1] === "news";
    setBoxShadow(yOffset > 0 ? "0 0 10px rgba(0,0,0,0.2)" : (isHome || isNews) ? "transparent" : boxshadow);
  }, [location.pathname, boxshadow]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return boxshadow;
};

const useScrollToHash = (hash) => {
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) animateScrollTo(element);
    }
  }, [hash]);
};

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const boxshadow = useBoxShadow(location);
  useScrollToHash(location.hash);

  const scrollToTop = useScrollTo();
  const scrollToContact = useScrollTo(".contactform");

  const toggleMenu = () => setShowMenu(!showMenu);
  const closeMenu = () => setShowMenu(false);

  const menuItems = [
    { path: "/", label: "ホーム", scrollTo: scrollToTop },
    { path: "/videolibrary", label: "Youtube", scrollTo: scrollToTop },
    { path: "/webinar", label: "Webinar", scrollTo: scrollToTop },
    { path: "/partner", label: "参加企業", scrollTo: scrollToTop },
    { path: "/news", label: "ニュース", scrollTo: scrollToTop },
    { path: "/#contactform", label: "お問合せ", scrollTo: scrollToContact },
  ];

  const isLinkActive = (path) => {
    if (path.includes("#")) {
      const [pathname, hash] = path.split("#");
      return location.pathname === pathname && location.hash === `#${hash}`;
    }
    return location.pathname === path && !location.hash;
  };

  return (
    <NavWrapper boxshadow={boxshadow}>
      <StyledNavbar expand="md">
        <LogoContainer>
          <Link to="/" onClick={scrollToTop}>
            <img src={Logo} alt="Nodomaru Logo" />
          </Link>
        </LogoContainer>
        {showMenu && (
          <SmallNav>
            <StyledNav navbar>
              {menuItems.map((item) => (
                <NavItem key={item.path}>
                  <StyledLink
                    to={item.path}
                    onClick={() => { closeMenu(); item.scrollTo(); }}
                    isActive={isLinkActive(item.path)}
                    small
                  >
                    {item.label}
                  </StyledLink>
                </NavItem>
              ))}
            </StyledNav>
          </SmallNav>
        )}
        <div onClick={toggleMenu}>
          {showMenu ? <StyledFontCancel icon={faTimesCircle} /> : <StyledFontAwesome icon={faBars} />}
        </div>
        <LargeNav className="ml-auto">
          <StyledNav navbar>
            {menuItems.map((item) => (
              <NavItem key={item.path}>
                <StyledLink
                  to={item.path}
                  onClick={item.scrollTo}
                  isActive={isLinkActive(item.path)}
                >
                  {item.label}
                </StyledLink>
              </NavItem>
            ))}
          </StyledNav>
        </LargeNav>
      </StyledNavbar>
    </NavWrapper>
  );
}

const NavWrapper = styled.div`
  z-index: 12;
  display: flex;
  flex: auto;
  align-items: center;
  position: fixed;
  color: #373737;
  background: white !important;
  box-shadow: ${(props) => props.boxshadow} !important;
  transition: 0.5s all;
  width: -webkit-fill-available;
  height: 70px;
  @media (max-width: 768px) {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }
`;
const LogoContainer = styled.div`
  @media (max-width: 576px) {
    z-index: 10;
  }
`;
const StyledNav = styled(Nav)`
  margin: auto;
  @media (max-width: 767.9px) {
    position: fixed;
    padding-top: 60px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0, 0.9);
    z-index: 5;
    width: 100%;
    li {
      padding: 4px;
      padding-left: 20px;
      a {
        font-size: 20px !important;
      }
    }
  }
`;
const StyledNavbar = styled(Navbar)`
  flex: auto;
  @media (min-width: 1200px) {
    flex: none;
    width: 1200px;
    margin: auto;
    padding-left: 17px;
    padding-right: 17px;
  }
  @media (min-width: 1024px) and (max-width: 1199px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 768px) and (max-width: 1023.9px) {
    padding-left: 50px;
    padding-right: 30px;
  }
  @media (max-width: 767.9px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 526px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 426px) {
    padding-left: 27px;
    padding右: 27px;
  }
`;
const StyledLink = styled(Link)`
  color: ${(props) => (props.small ? "white" : "black")} !important;
  line-height: 24px;
  margin-right: 33px;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: ${(props) => (props.small ? "20px" : "16px")};
  border-bottom: 4px ${(props) => (props.isActive ? "black" : "transparent")} solid;
  :hover {
    text-decoration: none;
    border-bottom: 4px black solid;
  }
  @media (max-width: 767.9px) {
    font-size: 13px;
    line-height: 42px;
    padding-left: 0.5em;
    position: relative;
    z-index: 10;
  }
`;

const LargeNav = styled.div`
  @media (max-width: 767.9px) {
    display: none;
  }
`;
const SmallNav = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
const StyledFontAwesome = styled(FontAwesomeIcon)`
  color: black !important;
  font-size: 30px;

  @media (min-width: 768px) {
    display: none;
  }
`;
const StyledFontCancel = styled(FontAwesomeIcon)`
  z-index: 10;
  position: absolute;
  top: 0;
  color: white;
  margin-top: 9px;
  font-size: 36px;
  right: 22px;
  @media (min-width: 768px) {
    display: none;
  }
`;
