import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { FormGroup } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";

export default class ContactForm extends Component {
  constructor(value) {
    super();
    this.state = {
      name: "",
      companyname: "",
      email: "",
      confirmemail: "",
      phonenumber: "",
      message: "",
      isSubmitted: false,
      error: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitContact = this.submitContact.bind(this);
  }
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };
  resetForm = () => {
    const form = {
      name: "",
      companyname: "",
      email: "",
      confirmemail: "",
      message: "",
      phonenumber: "",
    };

    this.setState(form);
  };

  submitContact = (e) => {
    e.preventDefault();

    const {
      name,
      companyname,
      email,
      confirmemail,
      phonenumber,
      message,
    } = this.state;
    const formData = new FormData();
    formData.append("your-name", name);
    formData.append("your-company", companyname);
    formData.append("your-email", email);
    formData.append("your-confirm", confirmemail);
    formData.append("your-number", phonenumber);
    formData.append("your-message", message);
    console.log(this.state);
    axios
      .post("https://wp.logilab.world/?rest_route=/contact-form-7/v1/contact-forms/58/feedback", formData)
      .then((res) => {
        this.setState({
          isSubmitted: true,
          error: false,
        });
        console.log(res);
        if (res.data.status === "mail_sent") {
          this.resetForm();
        }
      })
      .catch((err) => {
        this.setState({
          error: true,
          isSubmitted: false,
        });
        console.log(err);
      });
  };
  componentDidMount() {
    Aos.init(
      {
        duration: 1200,
      },
      []
    );
  }

  render() {
    const {
      name,
      companyname,
      email,
      confirmemail,
      phonenumber,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <Wrapper id="contactform" className="contactform">
          <h2>Contact us</h2>
          <StyledForm onSubmit={this.submitContact}>
            <StyledFormGroup>
              <StyledInput
                type="text"
                placeholder="お名前*"
                required
                name="name"
                id="name"
                value={name}
                onChange={this.handleChange("name")}
              />{" "}
              <br />
            </StyledFormGroup>

            <StyledFormGroup>
              <StyledInput
                type="text"
                placeholder="貴社名"
                name="name"
                id="bname"
                value={companyname}
                onChange={this.handleChange("companyname")}
              />{" "}
              <br />
            </StyledFormGroup>

            <StyledFormGroup>
              <StyledInput
                type="email"
                placeholder="メールアドレス*"
                required
                name="email"
                id="email"
                value={email}
                onChange={this.handleChange("email")}
              />{" "}
              <br />
            </StyledFormGroup>
            <StyledFormGroup>
              <StyledInput
                type="email"
                placeholder="メールアドレス(確認用)*	"
                name="cfemail"
                id="cfemail"
                value={confirmemail}
                onChange={this.handleChange("confirmemail")}
                
              />
              <br />
            </StyledFormGroup>
            <StyledFormGroup>
              <StyledInput
                type="number"
                placeholder="電話番号*	"
                name="phonenumber"
                id="phonenumber"
                value={phonenumber}
                onChange={this.handleChange("phonenumber")}
              />
              <br />
            </StyledFormGroup>

            <StyledFormGroup>
              <StyledTextArea
                type="text"
                
                required
                name="-ve-your-subject"
                id="m-ve-your-subject"
                value={message}
                onChange={this.handleChange("message")}
              />
            </StyledFormGroup>

            {/* <Required>*必須項目に入力してください</Required> */}
            <Submit type="submit" value="送信" />
          </StyledForm>
          {/* <SuccessMessage data-aos="slide-up">フォームの送信が完了しました</SuccessMessage> */}
          {this.state.isSubmitted && (
            <SuccessMessage data-aos="slide-up">
              フォームの送信が完了しました
            </SuccessMessage>
          )}
          {this.state.error && (
            <ErrorMessage data-aos="slide-up">FORM NOT SUBMITTED</ErrorMessage>
          )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  padding-top: 153px;
  h2 {
    font-weight: normal;
    font-size: 46px;
    line-height: 54px;
    margin-bottom: 35px;
  }
  @media (max-width: 768px) {
    padding-top: 53px;
  }
  @media (max-width: 426px) {
   
    padding-top: 55px;
  }
`;
const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0px !important;
`;
const StyledForm = styled.form`
  text-align: center;
`;

const StyledInput = styled.input`
  width: 350px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 10px;
  border-color: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: none
  @media (max-width: 991px) {
    width: 90%;
    margin: auto;
    margin-bottom: 22px;
  }
  :active {
    border: 1px solid black;
    outline: 0;
  }
  :focus {
    border: 1px solid black;
    outline: 0;
  }
  ::placeholder {
    color: #c4c4c4;
    padding-left: 25px;
    padding-top: 15px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    ::placeholder {
      color: #c4c4c4;
    }
  }
  @media (max-width: 425px) {
    width: 295px;
    height: 40px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    --webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: none
  }
`;
const StyledTextArea = styled.textarea`
  width: 350px;
  height: 150px;
  outline: 0;
  border-radius: 10px;
  border-color: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: none
  @media (max-width: 991px) {
    width: 90%;
    margin: auto;
  }

  // ::placeholder {
  //   color: #c4c4c4;
  //   padding-left: 25px;
  //   padding-top: 15px;
  //   @media(max-width:576px){
  //     display:none;
  //   }
  // }
  @media (max-width: 768px) {
    width: 100%;
  }
  // @media (max-width: 576px) {
  //   ::placeholder {
  //     display:none!important;
  //   }
  // }
  @media (max-width: 425px) {
    width: 295px;
    height: 150px;
    margin-bottom:56px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    --webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: none
  }
`;
// const Required = styled.div `
// font-size: 10px;
// line-height: 24px;
// color:black;
// text-align:left;
// height:26px;
// @media(max-width:991px){
//   width:90%;
//   margin:auto;

// }
// `
const Submit = styled.input`
  background-color: var(--lightBlue);
  ${"" /* padding:9px 25px 9px 25px; */}
  color:white;
  width: 86px;
  height: 40px;
  border-color: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  outline: 0;
  margin-bottom: 30px;
  :hover {
    color: black;
  }
`;

const SuccessMessage = styled.div`
  color: #0fc100;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  width: 302px;
  border: 1px solid #0fc100;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const ErrorMessage = styled.div`
color:red;
font-weight: bold;
font-size: 18px;
line-height: 24px;
margin-bottom:0px;
width:302px;
border:1px solid red
height:42px;
display:flex;
align-items:center;
justify-content:center;
margin:auto;
`;
