import React from "react";
import styled, { keyframes } from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";
import LogoOne from "../../assets/Lozi.svg";
import LogoTwo from "../../assets/sankyu.svg";
import LogoThree from "../../assets/standage.svg";
import LogoFour from "../../assets/and-local.svg";
// import LogoFive from "../../assets/Pasona.svg";

export default function Partners() {
  return (
    <React.Fragment>
      <Wrapper>
        <Container>
          <Title>PARTNERS</Title>
          <SubTitle>
          ロジラボは、物流業界を共に革新するパートナー企業を求めています
          </SubTitle>
          <Slider>
            <SliderItem>
              <img src={LogoOne} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoTwo} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoThree} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoFour} alt="Logi Lab partners" />
            </SliderItem>
            {/* <SliderItem>
              <img src={LogoFive} alt="Logi Lab partners" />
            </SliderItem> */}
            <SliderItem>
              <img src={LogoOne} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoTwo} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoThree} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoFour} alt="Logi Lab partners" />
            </SliderItem>
            {/* <SliderItem>
              <img src={LogoFive} alt="Logi Lab partners" />
            </SliderItem> */}
            <SliderItem>
              <img src={LogoOne} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoTwo} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoThree} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoFour} alt="Logi Lab partners" />
            </SliderItem>
            {/* <SliderItem>
              <img src={LogoFive} alt="Logi Lab partners" />
            </SliderItem> */}
            <SliderItem>
              <img src={LogoOne} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoTwo} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoThree} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoFour} alt="Logi Lab partners" />
            </SliderItem>
            {/* <SliderItem>
              <img src={LogoFive} alt="Logi Lab partners"/>
            </SliderItem> */}
            {/* <SliderItem>
              <img src={LogoFive} alt="Logi Lab partners" />
            </SliderItem> */}
            <SliderItem>
              <img src={LogoOne} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoTwo} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoThree} alt="Logi Lab partners" />
            </SliderItem>
            <SliderItem>
              <img src={LogoFour} alt="Logi Lab partners" className="motivate" />
            </SliderItem>
          </Slider>
        </Container>
      </Wrapper>
      <SmallWrapper>
        <SmallContainer>
          <Title>PARTNERS</Title>
          <SubTitle>
            ロジラボは、物流業界を共に革新するパートナー企業を募集しています
          </SubTitle>
          <OwlCarousel items="1" loop autoplay dots className='owl-theme'>
            <div className="item">
              <img src={LogoOne} alt="Logi Lab partners" />
            </div>
            <div className="item">
              <img src={LogoTwo} alt="Logi Lab partners" />
            </div>
            <div className="item">
              <img src={LogoThree} alt="Logi Lab partners" />
            </div>
            <div className="item">
              <img src={LogoFour} alt="Logi Lab partners" />
            </div>
            {/* <div className="item">
              <img src={LogoFive} alt="Logi Lab partners" />
            </div> */}
          </OwlCarousel>
        </SmallContainer>
      </SmallWrapper>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  background: var(--pink);
  height: 200px;
  position: relative;
  z-index: 3;
  overflow: hidden;
  @media (min-width: 1200px) {
    width: 1200px;
    margin: auto;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (max-width: 576px) {
    height: 208px;
  }
  :before {
    position: absolute;
    top: 0;
    width: 350px;
    height: 200px;
    content: "";
    z-index: 5;
    left: 0;
    background: linear-gradient(
      270deg,
      rgba(255, 57, 170, 0) 18.18%,
      #ff39aa 100%
    );
  }
  :after {
    background: linear-gradient(
      90deg,
      rgba(255, 57, 170, 0) 18.18%,
      #ff39aa 100%
    );
    position: absolute;
    top: 0;
    width: 350px;
    height: 200px;
    content: "";
    z-index: 5;
    right: 0;
  }
`;
const FloatLeft = keyframes`
 from {left:-1500px;}
  to {left: 0px}

@media(max-width:426px){
  from {left:0px;}
  to {left: 900px;}
}
`;
const Container = styled.div`
  padding-top: 31px;
  padding-bottom: 57px;
  position: relative;
  z-index: 4;
  text-align: center;

  @media (max-width: 1024px) {
    width: 90%;
    margin: auto;
  }
 
`;
const Slider = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  animation: ${FloatLeft} infinite;
  animation-duration: 30s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-direction: normal;
`;
const SliderItem = styled.div`
  width: 500px;
  img {
    width: 275px;
    height: 40px !important;
    margin: auto;
  }
`;

const Title = styled.h3`
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: white;
  
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 576px) {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }
`;
const SubTitle = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 19px;
  color: white;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 576px) {
    margin-bottom:25px;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }
`;
const SmallWrapper = styled.div`
  height: 208px;
  background-color: var(--pink);
  @media (min-width: 991.1px) {
    display: none;
  } ;
`;
const SmallContainer = styled.div`
  padding-top: 31px;
  padding-bottom: 57px;
  position: relative;
  z-index: 4;
  text-align: center;
  height:208px;
  img {
    width: 252px !important;
    height: 40px !important;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 450px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 300px;
    margin: auto;
    padding-top: 15px;
  }
  @media(max-width:426px){
    padding-bottom:15px;
  }
`;
