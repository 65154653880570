import React, { useState } from "react";
import styled from "styled-components";
import Banner from "../../assets/bg-5.png";
import SmallBanner from "../../assets/bg-5a.png";
import Arrow from "../../assets/Arrow.svg";
import Testimonial from "./Testimonial";
import imageOne from "../../assets/daisuke.png";
import imageTwo from "../../assets/nariko.png";
import imageThree from "../../assets/kenta.png";
import imageFour from "../../assets/roberts.png";
import quatercycle from "../../assets/halfcycle.png";
import { Link } from "react-router-dom";
import { HashLink as Linker } from "react-router-hash-link";
import animateScrollTo from "animated-scroll-to";

export default function About() {
  const [overlayOne, setOverlayOne] = useState(true);
  const [overlayTwo, setOverlayTwo] = useState(true);
  const [overlayThree, setOverlayThree] = useState(true);
  const [overlayFour, setOverlayFour] = useState(true);
  const ScrollToContact = () => {
    animateScrollTo(document.querySelector(".contactform"));
  };
  const OneHover = () => {
    setOverlayOne(false);
  };
  const OneUnHover = () => {
    setOverlayOne(true);
  };
  const TwoHover = () => {
    setOverlayTwo(false);
  };
  const TwoUnHover = () => {
    setOverlayTwo(true);
  };
  const ThreeHover = () => {
    setOverlayThree(false);
  };
  const ThreeUnHover = () => {
    setOverlayThree(true);
  };
  const FourHover = () => {
    setOverlayFour(false);
  };
  const FourUnHover = () => {
    setOverlayFour(true);
  };
  return (
    <Wrapper>
      <BannerSection>
        
        <Container>
          <TextSection>
            <TextContainer>
            <TextHeading>パートナー</TextHeading>
            <TextBody>
              “ロジスティクス・ラボ(Logistics Lab/ Logilab)“は山九株式会社、
              株式会社STANDAGE、株式会社LOZI
              が3社共同で設立した、貿易物流業界において先端テ
              クノロジーを活用するための研究・開発を目的とした合同研究機構である。
            </TextBody>
            </TextContainer>
          </TextSection>
          {/* <Center>
            <BannerButton>詳しく</BannerButton>
          </Center> */}

          <ArrowDown>
            {" "}
            <img src={Arrow} alt="Logi Lab" />
          </ArrowDown>
        </Container>
      </BannerSection>
      <Container>
        <WhoWeAre>
          <LeftWho>
            <LeftHeading>WHO WE ARE</LeftHeading>
            <LeftHeading>ロジラボとは？</LeftHeading>
            <LeftBody>
            “ロジラボ”－ロジスティックスラボは、最先端テクノロジーを駆使し、
            貿易・物流を革新することを目的として設立された合同機構です。 
            業種を超えた企業が、目的達成のために力を合わせ、ともに新規事業を創出して参ります。
            大事なのは熱い想い。私たちは、肩書や組織には捉われず、“貿易業界に革新を起こす” 
            そんな想いを重視しています。

            </LeftBody>
          </LeftWho>
          <RightWho>
            <img src="/assets/images/alliance.png" alt="About Logo Section" />
          </RightWho>
        </WhoWeAre>
      </Container>
      <Testimonial />
      <Faces>
        <FaceOne onMouseEnter={OneHover} onMouseLeave={OneUnHover}>
          <Link to="/profile">
            {overlayOne ? <OverlayOne /> : null}

            <img src={imageOne} alt="Logi Labo" />
          </Link>
        </FaceOne>

        <FaceTwo onMouseEnter={TwoHover} onMouseLeave={TwoUnHover}>
          <Link to="/profile">
            {overlayTwo ? <OverlayTwo /> : null}
            <img src={imageTwo} alt="Logi Labo" />
          </Link>
        </FaceTwo>

        <FaceThree onMouseEnter={ThreeHover} onMouseLeave={ThreeUnHover}>
          <Link to="/profile">
            {overlayThree ? <OverlayThree /> : null}
            <img src={imageThree} alt="Logi Labo" />
          </Link>
        </FaceThree>

        <FaceFour onMouseEnter={FourHover} onMouseLeave={FourUnHover}>
          <Link to="/profile">
            {overlayFour ? <OverlayFour /> : null}
            <img src={imageFour} alt="Logi Labo" />
          </Link>
        </FaceFour>

        <FacesButton to="/profile">参加企業</FacesButton>
      </Faces>
      <ConversationContainer>
        <Conversation>
          <LeftConverse>
            <LeftConWrapper>
              {" "}
              <LeftTextWrap>
                <h5>Join the Conversation</h5>
                <h3>パートナー募集</h3>
                <p>
                  ロジラボは、物流業界を共に革新するパートナー企業を募集しています！
                  ご興味のある企業様はお気軽にお問い合わせください。
                </p>
                <ConverseButton to="/#contactform" onClick={ScrollToContact}>参加する</ConverseButton>
              </LeftTextWrap>
              <LeftLine />
            </LeftConWrapper>

            <img src={quatercycle} alt="logi labo" />
          </LeftConverse>
          <RightConverse>
            <RightContent>
              <div>
                <h4>WHO YOU ARE</h4>
                <h4>求めるパートナー像</h4>
              </div>

              <p>
                メンバーは大企業とスタートアップが、同じ熱量と、
                スピード感を共有し、お互いのシナジーを大事にし、様々な実証実験を行っています。
                大企業のリソースにスタートアップのアイデアやテクノ
                ロジーを融合させる。単なる、情報交換でなく、最速でプロジェクトを実行させるのがロジラボのユニークな点です。
              </p>

              <p>
                自社にスタートアップのテクノロジーを持ち込みたい大企業。
                優秀な物流スタートアップと関係性をもちたい企業。
                大企業のリソースで自社のサービスやテクノロジーを実証したいスタートアップ企業
              </p>
            </RightContent>
          </RightConverse>
        </Conversation>
      </ConversationContainer>
      <SmallConversation>
        <ConvoButton>パートナー紹介</ConvoButton>
        <h2>Community Growth</h2>
        <h1>パートナー募集</h1>
        <ConvoButtonTwo>参加する</ConvoButtonTwo>
      </SmallConversation>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const BannerSection = styled.div`
  background: url(${Banner});
  background-size: cover;
  height: 558px;
  padding-top:139px;
  @media(max-width:576px){
    padding-top:0px;
  }
  @media (max-width: 426px) {
    background: url(${SmallBanner});
    background-size: cover;
    padding-top:0px;
  }
`;
// const Underlay = styled.div`
//   background: url(${AboutParallax});
//   width: 50%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   z-index: 0;
//   left: 0;
//   height: 558px;
//   position: absolute;
//   @media (max-width: 1199px) {
//     background-size: cover;
//     background-repeat: no-repeat;
//   }
//   @media (max-width: 1024px) {
//     display: none;
//   }
// `;
const Container = styled.div`
  @media (min-width: 1200px) {
    width: 1050px;
    margin: auto;
  }
  @media (max-width: 1199px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 90%;
    margin: auto;
  }
`;
const TextSection = styled.div`
background: rgba(0, 0, 0, 0.5);
width:451px;
height:350px;
margin-left:auto;
@media (max-width: 768px) {
margin:auto;
width:100%;
height:300px;
}
@media (max-width: 576px) {
  background:transparent;
  margin:auto;
  width:100%;
  height:100z;
  }
`;
const TextContainer = styled.div `
  padding-right:58px;
  padding-top: 38px;
padding-left:58px;
  position: relative;
  z-index: 2;
  
  @media (max-width: 576px) {
    width: 314px;
    margin: auto;
    padding-top: 288px;
    background:transparent;
  }
`
const TextHeading = styled.h2`
  font-weight: 400;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
  margin-bottom: 7px;
`;
const TextBody = styled.p`
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  font-weight:300;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
   
  }
`;
// const BannerButton = styled.button`
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 24px;
//   position: relative;
//   z-index: 2;
//   padding-left: 51px;
//   padding-right: 51px;
//   line-height: 24px;
//   border-color: transparent;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   padding-top: 8px;
//   padding-bottom: 8px;
//   border-radius: 10px;
//   margin: auto;
//   background-color: white;
//   @media (max-width: 426px) {
//     margin-top: 15px;
//     margin: auto;
//   }
// `;
const ArrowDown = styled.div`
  padding-top: 13px;
  width: 25px;
  margin: auto;
  position: relative;
  z-index: 2;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 426px) {
    padding-top: 18px;
  }
`;
// const Center = styled.div`
//   text-align: center;
// `;
const WhoWeAre = styled.div`
  display: flex;
  align-items: center;
  padding-top: 49px;
  padding-bottom: 43px;
  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }
`;
const LeftWho = styled.div`
  width: 485px;
  @media (max-width: 576px) {
    width: 350px;
    margin: auto;
  }
  @media (max-width: 375px) {
    width: 252px;
    margin: auto;
  }
`;
const LeftHeading = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 9px;
  @media (max-width: 991px) {
    text-align: center;
  }
`;
const LeftBody = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight:400;
`;
const RightWho = styled.div`
  width: 480px;
  margin-left: auto;
  img {
    width: 100%;
  }
  @media (max-width: 1199px) {
    width: 450px;
  }
  @media (max-width: 1024px) {
    width: 400px;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;

const Faces = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  div {
    width: 25%;
  }
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    div {
      width: 50%;
    }
  }
`;
const FaceOne = styled.div`
  position: relative;
  width: 25%;
`;
const OverlayOne = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 174, 239, 0.25);
  width: 100% !important;
`;
const OverlayTwo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 174, 239, 0.25);
  width: 100% !important;
`;
const OverlayThree = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 174, 239, 0.25);
  width: 100% !important;
`;
const OverlayFour = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 174, 239, 0.25);
  width: 100% !important;
`;
const FaceTwo = styled.div`
  position: relative;
`;
const FaceThree = styled.div`
  position: relative;
`;
const FaceFour = styled.div`
  position: relative;
`;

const FacesButton = styled(Link)`
  padding: 8px 51px;
  background: var(--lightBlue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  position: absolute;
  z-index: 3;
  border-radius: 10px;
  border-color: transparent;
  margin-bottom: 45px;
  :hover {
    
    color: white;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Conversation = styled.div`
  display: flex;
  width: 100%;
`;
const ConversationContainer = styled.div`
  @media (min-width: 1200px) {
    width: 100%;
    margin: auto;
  }
  @media (min-width: 1400px) {
    width: 1200px;
    margin: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const LeftConverse = styled.div`
  background-color: white;
  width: 50%;
  img {
    width: 520px;
    position: absolute;
    left: 0;
    margin-top: 52px;
  }
  @media (max-width: 1024px) {
    img {
      width: 420px;
    }
  }
`;
const LeftConWrapper = styled.div`
  display: flex;
  padding-top: 68px;
  padding-left: 80px;
  @media (max-width: 991px) {
    padding-left: 40px;
  }
`;
const LeftTextWrap = styled.div`
  width: 302px;
  h5 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
  }
  p {
    font-size: 24px;
    line-height: 32px;
    margin-bottom:52px;
    font-weight:400;
  }
 
`;
const ConverseButton = styled(Linker) `
background-color: var(--pink);
font-weight:bold;
    color: white;
    padding: 8px 51px;
    border-radius: 10px;
    border: transparent;
    :hover{
      color:white;
    }
`
const LeftLine = styled.div`
  width: 145px;
  background-color: var(--pink);
  margin-top: 58px;
  height: 5px;
  margin-left: auto;
  @media (max-width: 991px) {
    width: 90px;
  }
`;
const RightConverse = styled.div`
  background-color: var(--lightBlue);
  width: 50%;
  padding-bottom: 300px;
  h4 {
    color: white;
    font-weight:400;
  }
  @media (max-width: 1024px) {
    padding-bottom: 200px;
  }
`;
const RightContent = styled.div`
  width: 354px;
  padding-left: 49px;
  padding-top: 15px;
  color: white;

  div {
    padding-bottom: 32px;
  }
`;

const SmallConversation = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  width: 250px;
  margin: auto;
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-top: 27px;
  }
  h1 {
    font-weight: bold;
    font-size: 46px;
    line-height: 54px;
    margin-top: 8px;
  }
  @media (min-width: 768.1px) {
    display: none;
  }
`;
const ConvoButton = styled.button`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-align: center;
  background-color: var(--lightBlue);
  padding: 8px 51px;
  border-radius: 10px;
  border-color: transparent;
`;
const ConvoButtonTwo = styled.button`
  background-color: var(--pink);
  padding: 8px 51px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-align: center;
  margin-top: 32px;
  border-color: transparent;
`;
