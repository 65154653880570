import React from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";

export default function Testimonial() {
  return (
    <React.Fragment>
      <Wrapper>
        <Content>
          <OwlCarousel
            items="1"
            className="owl-theme" loop autoplay dots={false}>
            <div className="item">
              <h5>
                “メンバーは大企業とスタートアップが、同じ熱量と、
                スピード感を共有し、お互いのシナジーを大事にし、
                様々な実証実験を行っています。大企業のリソースにスタートアップのアイデアやテクノロジーを融合させる。単なる、情報交換でなく、最速でプロジェクトを実行させるのがロジラボのユニークな点です。”
              </h5>
              <h6>大森健太 / COO、株式会社STANDAGE</h6>
            </div>
            <div className="item">
              <h5>
                Logilabには新しい価値を生み出すための活動ができるメンバーが揃っています。社内外の様々なバックグラウンドをもつ人々が、積極的に先端デジタル技術やユニークアイディアを持ち寄り、価値創造や共創を目指した議論を日々展開しています。また実際のフィールドでクイックに
                検証できるのも、Logilabの優位点であると考えています。
              </h5>
              <h6>
                面谷斗夢 / 取締役、株式会社アンドローカル
              </h6>
            </div>
            <div className="item">
              <h5>
                物を安全に、スムーズに運びたい、日本の技術や製品を海外の人に知ってもらいたい
                という思いで‘Logilab’を設立しました。Logilabは、単に新しい技術や価値を生み出すだすだけの技能集団ではなく、人の助けになりたいという熱い思いがある集団です。スピード感を持ち、どんどん新しい事にチャレンジしてしていきたいと思っています。
              </h5>
              <h6>山九株式会社　LS営業部　中村成道子</h6>
            </div>
          </OwlCarousel>
        </Content>
        <SmallContent>
          <OwlCarousel items="1" className="owl-theme" loop dots autoplay>
            <div className="item">
              <SlideWrapper>
                <h2>
                  物を安全に、スムーズに運びたい、日本の技術や製品を海外の人に知ってもらいたい
                  という思いで‘Logilab’を設立しました。Logilabは、単に新しい技術や価値を生み出すだすだけの技能集団ではなく、人の助けになりたいという熱い思いがある集団です。スピード感を持ち、どんどん新しい事にチャレンジしてしていきたいと思っています。
                </h2>
                <h5>山九株式会社　LS営業部　中村成道子</h5>
              </SlideWrapper>
            </div>
            <div className="item">
              <SlideWrapper>
                <h2>
                  Logilabには新しい価値を生み出すための活動ができるメンバーが揃っています。社内外の様々なバックグラウンドをもつ人々が、積極的に先端デジタル技術やユニークアイディアを持ち寄り、価値創造や共創を目指した議論を日々展開しています。また実際のフィールドでクイックに
                  検証できるのも、Logilabの優位点であると考えています。
                </h2>
                <h5>大森健太 / COO、株式会社STANDAGE</h5>
              </SlideWrapper>
            </div>
            <div className="item">
              <SlideWrapper>
                <h2>
                  “勝どれ案投ケトウエ副同ホケハシ指解ッか害禁クドふの況面数円オツセラ先御2議ルエムス大属”
                </h2>
                <h5>大森健太 / COO、株式会社STANDAGE</h5>
              </SlideWrapper>
            </div>
          </OwlCarousel>
        </SmallContent>
      </Wrapper>
    </React.Fragment>
  );
}

const Wrapper = styled.div`
  background-color: var(--pink);
  padding-top: 24px;
  padding-bottom: 32px;
  @media (max-width: 425px) {
    padding-top: 19px;
    padding-bottom: 22px;
  }
`;
const Content = styled.div`
  width: 873px;
  margin: auto;
  h5 {
    margin-bottom: 0px;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: white;
    text-align: center;
    padding-bottom: 12px;
  }
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-align: center;
    margin-top: 5px;
  }
  @media (max-width: 991px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 576px) {
    h5 {
      font-size: 18px;
      line-height: 26px;
    }
    h6 {
      font-size: 14px;
      line-height: 22px;
    }
  }
  @media (max-width: 425px) {
    display: none;
  }
`;
const SmallContent = styled.div`
  @media (min-width: 425.1px) {
    display: none;
  }
  @media (max-width: 425px) {
    .owl-theme .owl-nav.disabled + .owl-dots {
      margin-top: 8px !important;
    }
  }
`;
const SlideWrapper = styled.div`
  width: 265px;
  margin: auto;
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: white;
  }
  h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: white;
    text-align: center;
  }
`;
