import React from "react";
import styled from "styled-components";
import { AppConsumer } from "../../Context";
import Spinner from "../Spinner";
import renderHTML from "react-render-html";
import FeaturedImage from "../../assets/Featured.png";

export default function Featured() {
  const truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  return (
    <React.Fragment>
      <AppConsumer>
        {(value) => {
          const { AllPost } = value;
          if (
            AllPost === undefined ||
            !AllPost ||
            AllPost.AllPost === undefined ||
            AllPost.length === 0 ||
            !AllPost.AllPost
          ) {
            return <Spinner />;
          } else {
            return (
              <div>
                {AllPost.AllPost.slice(0, 1).map((post) => {
                  if (post["_embedded"]["wp:featuredmedia"] === undefined) {
                    return (
                      <React.Fragment key={post.title.rendered}>
                        <Wrapper>
                          <LeftWrapper
                            backgroundImage={FeaturedImage}
                          ></LeftWrapper>
                          <RightWrapper>
                            <h3>Featured Article</h3>
                            <h4>{renderHTML(post.title.rendered)}</h4>
                            <div>{renderHTML(post.excerpt.rendered)}</div>
                          </RightWrapper>
                        </Wrapper>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={post.title.rendered}>
                        <Wrapper>
                          <LeftWrapper
                            backgroundImage={`${post["_embedded"]["wp:featuredmedia"]["0"]["source_url"]}`}
                          ></LeftWrapper>
                          <RightWrapper>
                            <h3>Featured Article</h3>
                            <h4>{renderHTML(post.title.rendered)}</h4>
                            <div>{renderHTML(truncate(post.excerpt.rendered))}</div>
                          </RightWrapper>
                        </Wrapper>
                      </React.Fragment>
                    );
                  }
                })}
              </div>
            );
          }
        }}
      </AppConsumer>
    </React.Fragment>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  align-items: center;
  @media (min-width: 1200px) {
    width: 1150px;
    margin: auto;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const LeftWrapper = styled.div`
  background: url(${(props) => props.backgroundImage});
  height: 300px;
  background-size: cover;
  width: 550px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const RightWrapper = styled.div`
  margin-left: 50px;
  width: 521px;
  margin-top: 49px;

  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 9px;
  }
  h4 {
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 11px;
  }
  div {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
  }
  @media (max-width: 1199px) {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0px;
    padding-top: 20px;
  }
  @media (max-width: 991px) {
    width: 90%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  h3 {
    width: 521px;
    margin: auto;
  }
  h4 {
    width: 521px;
    margin: auto;
  }
  div{
    width: 521px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
    padding-bottom: 20px;
  }
  h3 {
    width: 100%;
  }
  h4 {
    width: 100%;
  }
  div {
    width: 100%;
  }
`;
