import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import dayjs from "dayjs";
import Pagination from "react-js-pagination";
import bannerimage from "../../assets/bg-6.png";
import Spinner from "../Spinner";
import "./VideoList.modules.css";
import noVideo from "../../assets/no-video.png";
import api from "../../lib/api";

class VideoLibrary extends Component {
  state = {
    featuredVideo: null,
    albums: [],
    index: null,
    postTitle: "",
    result: [],
    itemsPerPage: 24,
    searchPage: 1,
    searching: false,
    loading: false,
    loadingAlbumVideos: false,
  };

  videoCache = {};

  setFeaturedVideo = (video) => {
    this.setState({ featuredVideo: video });
  };

  getAlbumIndex() {
    const { pathname } = this.props.location;
    if (pathname.includes("webinar")) {
      return 0; // Index of the first album
    }
    if (pathname.includes("videolibrary")) {
      return 1; // Index of the second album
    }
    return null;
  }

  loadAlbums = async (index) => {
    this.setState({ loadingAlbumVideos: true });
    try {
      const response = await api.getVimeo("/users/136968250/albums", {
        params: { per_page: 100, page: 1 },
      });
      const allAlbums = response.data.data;

      const album = allAlbums[index];

      if (!album) {
        this.setState({ loadingAlbumVideos: false });
        return;
      }

      const videosResponse = await api.getVimeo(`${album.uri}/videos?sort=manual`, {
        params: { per_page: this.state.itemsPerPage, page: 1 },
      });

      const cacheKey = `${album.uri}-1`;
      this.videoCache[cacheKey] = videosResponse.data.data;

      this.setState({
        albums: [{
          ...album,
          videos: videosResponse.data.data,
          totalVideos: videosResponse.data.total,
          currentPage: 1,
        }],
        loadingAlbumVideos: false,
      });

      if (videosResponse.data.data.length > 0) {
        this.setFeaturedVideo(videosResponse.data.data[0]);
      }
    } catch (error) {
      console.error("Error loading albums:", error);
      this.setState({ loadingAlbumVideos: false });
    }
  };

  componentDidMount = async () => {
    const index = this.getAlbumIndex();
    this.setState({ index }, () => {
      this.loadAlbums(index);
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const index = this.getAlbumIndex();
      this.setState({ index }, () => {
        this.loadAlbums(index);
      });
    }
  }

  handleVideoPageChange = async (albumUri, pageNumber) => {
    const cacheKey = `${albumUri}-${pageNumber}`;
    if (this.videoCache[cacheKey]) {
      // Found in cache, update albums state without fetching
      this.setState(prevState => ({
        albums: prevState.albums.map(album =>
          album.uri === albumUri ? { ...album, videos: this.videoCache[cacheKey], currentPage: pageNumber } : album
        ),
      }));
    } else {
      // Not found in cache, fetch videos and update cache
      try {
        this.setState({ loadingAlbumVideos: true });
        const response = await api.getVimeo(`${albumUri}/videos?sort=manual`, {
          params: { per_page: this.state.itemsPerPage, page: pageNumber },
        });

        this.videoCache[cacheKey] = response.data.data;

        this.setState(prevState => ({
          albums: prevState.albums.map(album =>
            album.uri === albumUri ? { ...album, videos: response.data.data, currentPage: pageNumber } : album
          ),
          loadingAlbumVideos: false,
        }));
      } catch (error) {
        console.error('Error fetching videos for page:', pageNumber, error);
        this.setState({ loadingAlbumVideos: false });
      }
    }
  }

  onSearchChange = (e) => {
    const searchTerm = e.target.value;
    this.setState({
      [e.target.name]: searchTerm,
    });

    if (searchTerm === "") {
      this.setState({
        result: [],
        searching: false,
        searchPage: 1,
      });
    }
  }

  findPost = async (e) => {
    e.preventDefault();
    this.setState({ searching: true, loading: true });

    const query = this.state.postTitle;
    if (!query) {
      alert('Please insert key words');
      this.setState({ loading: false });
      return;
    }

    let allResults = [];
    let page = 1;
    let totalPages = 1;

    try {
      while (page <= totalPages) {
        const { data } = await api.getVimeo('/users/136968250/videos', {
          params: {
            query,
            per_page: 50,
            page,
          },
        });
        allResults = allResults.concat(data.data);
        totalPages = Math.ceil(data.total / 50);
        page += 1;
      }

      this.setState({
        result: allResults,
        loading: false,
      });
    } catch (error) {
      console.error('Error searching videos', error);
      this.setState({ searching: false, loading: false });
    }
  }

  handleSearchPageChange = (pageNumber) => {
    this.setState({ searchPage: pageNumber });
  }

  renderVideoOrderAndCustomizeDate = (totalVideos, videoPerPage, currentPage, index, date, albumIndex) => {
    const order = totalVideos - ((currentPage - 1) * videoPerPage) - index;
    const justifyContentStyle = albumIndex !== 0 ? 'space-between' : 'flex-end';

    return (
      <div style={{ display: 'flex', justifyContent: justifyContentStyle }}>
        {albumIndex !== 0 && <span style={{ paddingLeft: '12px' }}>No.{order}</span>}
        <span>{dayjs(date).format('YYYY-MM-DD')}</span>
      </div>
    )
  }

  renderSearchResults = () => {
    const { searchPage, result, itemsPerPage, postTitle } = this.state;

    if (this.state.loading) {
      return <Spinner />;
    }

    if (result.length === 0) {
      return <h6>No search result for "{postTitle}"</h6>;
    }
    return (
      <>
        <h6>Search Results for "{postTitle}"</h6>
        <SingleVideoContainer className="container-wrapper">
          {result.slice((searchPage - 1) * itemsPerPage, searchPage * itemsPerPage).map(video => (
            <SingleVideo key={video.uri}>
              <Vimeo video={video.link} autoplay={false} width="342" height="192" />
              <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {dayjs(video.description).format('YYYY-MM-DD')}
              </span>
              <VideoTitle>{video.name}</VideoTitle>
            </SingleVideo>
          ))}
        </SingleVideoContainer>
        <PaginationWrapper>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={searchPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={result.length}
            pageRangeDisplayed={5}
            onChange={this.handleSearchPageChange}
          />
        </PaginationWrapper>
      </>
    );
  }

  renderAlbumVideos = () => {
    const { albums, loadingAlbumVideos, itemsPerPage } = this.state;
    if (loadingAlbumVideos) {
      return <Spinner />;
    }

    if (!albums.length) {
      return <h6>No album found</h6>;
    }

    return albums.map((album, albumIndex) => (
      <div className="video-zone" key={`video-zone-${albumIndex}`}>
        <div key={album.uri}>
          <VideoLibraryTitle>{album.name}</VideoLibraryTitle>
          <div className="container-wrapper">
            {album.videos.length > 0 ? (
              album.videos.map((video, index) => (
                <SingleVideo key={video.uri}>
                  <Vimeo className="vimeo" video={video.link} autoplay={false} width="342" height="192" showPortrait showTitle />
                  {this.renderVideoOrderAndCustomizeDate(album.totalVideos, itemsPerPage, album.currentPage, index, video.description, albumIndex)}
                  <h4>{video.name}</h4>
                </SingleVideo>
              ))
            ) : (
              <img src={noVideo} alt="No Videos" style={{ width: "100%", height: "auto" }} />
            )}
          </div>
          {album.totalVideos > itemsPerPage && (
            <PaginationWrapper>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={album.currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={album.totalVideos}
                pageRangeDisplayed={5}
                onChange={pageNumber => this.handleVideoPageChange(album.uri, pageNumber)}
              />
            </PaginationWrapper>
          )}
        </div>
      </div>
    ));
  }

  render() {
    const { index, featuredVideo, postTitle, searching } = this.state;
    return (
      <React.Fragment>
        <BannerSection>
          <Container>
            <h2>{index === 0 ? "Webinar" : "VIDEO LIBRARY"}</h2>
          </Container>
        </BannerSection>
        {featuredVideo ? (
          <FeaturedWrapper>
              <>
                <Vimeo
                  video={featuredVideo.link}
                  autoplay={false}
                  width="550"
                  height="300"
                />
                <h4>{featuredVideo.name}</h4>
              </>
          </FeaturedWrapper>
        ) : (
          <Spinner />
        )}
        <SearchWrapper>
          <SearchContainer>
            <SearchTitle>
              <p>Searching here</p>
            </SearchTitle>
            <StyledForm onSubmit={this.findPost}>
              <SearchFlex>
                <StyledInput
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Search Videos"
                  id="searchbutton"
                  name="postTitle"
                  value={postTitle}
                  onChange={this.onSearchChange}
                  autoComplete="off"
                />
              </SearchFlex>
            </StyledForm>
          </SearchContainer>
        </SearchWrapper>
        <Content>
          {searching ? this.renderSearchResults() : this.renderAlbumVideos()}
        </Content>
      </React.Fragment>
    );
  }
}

const BannerSection = styled.div`
  @media (min-width: 576.1px) {
    background: url(${bannerimage});
    background-size: cover;
    height: 220px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 576px) {
    background: url(${bannerimage});
    background-size: cover;
    height: 220px;
    display: flex;
    align-items: center;
  }
`;
const Container = styled.div`
  width: 90%;
  margin: auto;
  @media (min-width: 1200px) {
    width: 1150px;
    margin: auto;
  }
  h2 {
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
    padding-top: 70px;
  }
  @media (max-width: 1199px) {
    width: 95%;
    margin: auto;
  }
  @media (max-width: 1024px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding-left: 0px;
  }
`;
const FeaturedWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  @media (min-width: 1200px) {
    width: 1150px;
    margin: auto;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const SearchWrapper = styled.div`
  background-color: var(--pink);
  height: 90px;
  width: 100%;
  margin-bottom: 60px;
`;
const SearchContainer = styled.div`
  width: 90%;
  margin: auto;
  height: 90px;
  display: flex;
  align-items: center;
  @media (min-width: 1200px) {
    width: 1200px;
    padding-left: 20px;
    padding-right: 77px;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 30px;
  }
`;
const SearchTitle = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  svg {
    width: 15px;
    height: 15px;
    color: white;
    margin-left: 10px;
    font-size: 16px;
  }
`;
const SearchFlex = styled.div`
  display: flex;
  padding-right: 12px;
  border-radius: 10px;
  margin: auto;
  @media (max-width: 426px) {
    margin-left: auto;
  }
`;
const StyledInput = styled.input`
  width: 523px;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  border-color: transparent;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  :focus {
    box-shadow: none !important;
    border: none;
    border-color: transparent;
  }
  ::placeholder {
    color: #c4c4c4;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 20px;
  }
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 153px;
  }
  @media (max-width: 425px) {
    width: 153px;
    margin: auto;
    font-size: 12px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  width: 523px;
  margin-left: auto;
  @media (max-width: 768px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 153px;
  }
  @media (max-width: 426px) {
    width: 153px;
  }
`;
const SingleVideo = styled.div`
  width: 342px;
  padding-top: 12px;
  padding-bottom: 12px;
  h4 {
    font-size: 16px;
    line-height: 24px;
  }
`;
const VideoLibraryTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 14px;
  padding-top: 24px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const SingleVideoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1200px) {
    width: 1066px;
    margin: auto;
    justify-content: space-between;
  }
  @media (max-width: 1200px) {
    width: 750px;
    margin: auto;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    width: 350px;
    margin: auto;
    justify-content: space-between;
  }
`;
const VideoTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  color: var(--darkGray);
  justify-content: center;
  margin: 24px auto;
  .active {
    a {
      background-color: white !important;
      color: var(--mainOrange) !important;
      border-color: 1px solid var(--mainOrange) !important;
    }
  }

  a {
    color: var(--darkGray) !important;
    background-color: white;
    border-color: 1px solid var(--darkGray) !important;
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 33px;
    padding: 5px 12px;
    border-radius: 4px;
    justify-content: center;
    font-size: 14px;
    line-height: 22px;
  }
  .page-item-disabled {
    display: none;
  }

  .page-item:last-child .page-link {
    display: none;
  }
  .page-item:first-child .page-link {
    display: none;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Content = styled.div`
  @media (min-width: 1200px) {
    width: 1066px;
    margin: auto;
  }
  @media (max-width: 1200px) {
    width: 750px;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 350px;
    margin: auto;
  }
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  .video-zone {
    display: flex;
    flex-direction: column;
  }
  .container-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 48px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
`;

export default withRouter(VideoLibrary);
