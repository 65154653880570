import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";
import './Home.modules.css';
import animateScrollTo from "animated-scroll-to";
import HomeBackground from "../../assets/bg-3.png";
import Globe from "../../assets/Globe.svg";
import Shadow from "../../assets/shadow.png";
import Arrow from "../../assets/Arrow.png";
import Cycle from "../../assets/cycle.png";
import Digital from "../../assets/bg-2.png";
import DigitalTwo from "../../assets/bg-4.png";
import SmallDigital from "../../assets/bg-4a.png";
import Parallax from "../../assets/Parallax.png";
import Partners from "./Partners";
import Contact from "./Contact";
import VidThumbnail from "../../assets/VidThumbnail.png";
import SmallVid from "../../assets/SmallVid.png";
// import YoutubeLogo from "../../assets/yt-logo.svg";
import WhiteYoutube from "../../assets/yt-logo-white.svg";
import Play from "../../assets/play.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export default function Home() {
  useEffect(() => {
    Aos.init(
      {
        duration: 1200,
      },
      []
    );
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const ScrolltoMotivate = () => {
    animateScrollTo(document.querySelector(".motivate"));
  };
  const ScrolltoNext = () => {
    animateScrollTo(document.querySelector(".next"));
  };

  return (
    <React.Fragment>
      <BannerWrapper>
        <Container>
          <BannerContainer>
            <SmallRightWrapper>
              <GlobeImg src={Globe} alt="Logi Labo" />
              <ShadowImg src={Shadow} alt="Globe Shadow" />
            </SmallRightWrapper>
            <LeftWrapper>
              <PreText>ミッション＆ビジョン</PreText>
              <Text>先端テクノロジーで貿易業界に革新をもたらす</Text>
              <Button onClick={ScrolltoMotivate}>詳しく</Button>
            </LeftWrapper>
            <RightWrapper>
              <GlobeImg src={Globe} alt="Logi Labo" />
              <ShadowImg src={Shadow} alt="Globe Shadow" />
            </RightWrapper>
          </BannerContainer>
          <ArrowDown onClick={ScrolltoNext} className="next" id="trigger-left">
            {" "}
            <img src={Arrow} alt="Logi Lab" />
          </ArrowDown>
        </Container>
      </BannerWrapper>
      <ConversionWrapper>
        <SmallRightConversion>
          <h5>
            DX化、日本はすでに周回遅れ。
            <br />
            2年後、どちらになりたいですか？
          </h5>
          <h2>
            DX化で、たった2年で
            <br />
            <span>売上に200%</span>(2倍) の差！*
          </h2>
        </SmallRightConversion>
        <LeftConversion>
          <SmallStat>
            *Based on profitable case studies in similar businesses
          </SmallStat>
        </LeftConversion>
        <RightConversion>
          <h5 data-aos="fade-up" style={{ transitionDuration: "2.4s" }}>
            DX化、日本はすでに周回遅れ。
            <br />
            2年後、どちらになりたいですか？
          </h5>
          <h2 data-aos="fade-up" style={{ transitionDuration: "3.6s" }}>
            DX化で、たった2年で <br />
            <span>売上に200%</span>(2倍) の差！*
          </h2>
          <h4 data-aos="fade-up" style={{ transitionDuration: "4s" }}>
            {" "}
            “DX先進国”-中国の物流企業の例：
            <br />
            <span style={{ color: "#00AEEF" }}>DX化を先駆けて進める某物流企業</span>・
            <span style={{ color: "#FF39AA"}}>アナログな某物流企業</span>
          </h4>
          <p data-aos="fade-up" style={{ transitionDuration: "4.2s" }}>
            {" "}
            *Based on profitable case studies in similar businesses
          </p>
        </RightConversion>
      </ConversionWrapper>

      <Example>
        <Container>
          <UnderlayWrapper
            data-aos-anchor="#trigger-left"
            data-aos-anchor-placement="top-top"
            data-aos="fade-right"
            style={{ transitionDuration: "3.6s" }}
          ></UnderlayWrapper>
          <ExampleText>ロジラボが実証するデジタル化事例</ExampleText>
          <ExampleButton to="/news">最新ニュース</ExampleButton>
        </Container>
      </Example>
      <SmallExample>
        <Container>
          <SmallUnderlayWrapper></SmallUnderlayWrapper>

          <ExampleText>ロジラボが実証するデジタル化事例</ExampleText>
          <ExampleButton to="/news">最新ニュース</ExampleButton>
        </Container>
      </SmallExample>
      <Partners />

      <VideoContainer>
        <VideoSection>
          <LeftVideo>
            <img src={VidThumbnail} alt="Logilab Video Thumbnail" />
            <ThumbnailDivider />
          </LeftVideo>
          <RightVideo>
            {/* <img
              src={YoutubeLogo}
              alt="Logilab Youtube Logo"
              className="whiteyt"
            /> */}
            <VideoHeading>物流・貿易業界の方々必見！！</VideoHeading>
            <VideoSubHeading>
              ホットなニュースを、短編動画でお届けしています！
            </VideoSubHeading>
            <Center>
              <VideoButton onClick={toggle}>
                <div>動画をみる </div>
                <div>
                  <img src={Play} alt="Logilab Play" />{" "}
                </div>
              </VideoButton>
              <a href="/assets/pdf/logilabo-introduction.pdf" download="logilabo-introduction.pdf"><SmallButton>ダウンロード</SmallButton></a>
            </Center>
          </RightVideo>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  title="vimeo"
                  src="https://player.vimeo.com/video/533129898"
                  frameborder="0"
                  allow="autoplay; 
                  fullscreen; 
                  picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </ModalBody>
          </Modal>
        </VideoSection>
        <SmallVideoSection>
          <UnderlayVid></UnderlayVid>
          <img
            src={WhiteYoutube}
            alt="Logilab Youtube Logo"
            className="whiteyt"
          />
          <VideoHeading>物流・貿易業界の方々必見！！</VideoHeading>
          <VideoSubHeading>
            ホットなニュースを、短編動画でお届けしています！
          </VideoSubHeading>
          <Center>
            <VideoButton onClick={toggle}>
              <div>動画をみる </div>
              <div>
                <img src={Play} alt="Logilab Play" />{" "}
              </div>
            </VideoButton>
            <a href="/assets/pdf/logilabo-introduction.pdf" download="logilabo-introduction.pdf"><SmallButton>ダウンロード</SmallButton></a>
          </Center>
        </SmallVideoSection>
      </VideoContainer>
      <Container>
        <ContactArea>
          <LeftContact>
            <h3>Founding Motivation</h3>
            <h3>設立の背景・想い</h3>
            <Contact />
          </LeftContact>
          <RightContact>
            <h2>“物流業界を変革する”</h2>
            <h5>
              IT、AI、ブロックチェーンなど最先端テクノロジーを取り入れている企業が多い昨今、
              日本の物流業界はデジタル化が大きく遅れており、非効率で属人的な業務がほとんどです。
            </h5>
            <h5>
              日本は今後、人口が減少し、伝統的な技術や企業の後継者不足、外資企業参入の増加、この度の新型コロナウイルスによる倒産など、深刻な問題が山積しています。
            </h5>
            <h5>時代は刻一刻と変化していくのに、変わらない物流業界。</h5>
            <h5>
              そんな変わらないレガシーな業界だからこそ、テクノロジーの力で大きく変革できる。
              今後の日本の行く末を考えた時に、大きな変革が必要不可欠である。
            </h5>
            <h5>
              そんな想いから、大手企業やスタートアップの枠組みは関係なく、業界を革新するために、ともにアイディアを出し合い、実証実験が行える場として「ロジラボ」を設立いたしました
            </h5>
          </RightContact>
        </ContactArea>
        <SmallContact>
          <LeftContact>
            <h3>Founding Motivation</h3>
            <h3>設立の背景・想い</h3>
          </LeftContact>
          <RightContact>
            <h2>“物流業界を変革する”</h2>
            <h5>
              IT、AI、ブロックチェーンなど最先端テクノロジーを取り入れている企業が多い昨今、
              日本の物流業界はデジタル化が大きく遅れており、非効率で属人的な業務がほとんどです。
            </h5>
            <h5>
              日本は今後、人口が減少し、伝統的な技術や企業の後継者不足、外資企業参入の増加、この度の新型コロナウイルスによる倒産など、深刻な問題が山積しています。
            </h5>
            <h5>時代は刻一刻と変化していくのに、変わらない物流業界。</h5>
            <h5>
              そんな変わらないレガシーな業界だからこそ、テクノロジーの力で大きく変革できる。
              今後の日本の行く末を考えた時に、大きな変革が必要不可欠である。
            </h5>
            <h5>
              そんな想いから、大手企業やスタートアップの枠組みは関係なく、業界を革新するために、ともにアイディアを出し合い、実証実験が行える場として「ロジラボ」を設立いたしました
            </h5>
            <Center>
              <SmallButton>参加希望</SmallButton>
            </Center>
          </RightContact>
          <Contact />
        </SmallContact>
      </Container>
    </React.Fragment>
  );
}
const BannerWrapper = styled.div`
  background: url(${HomeBackground});
  background-size: cover;
  padding-bottom: 33px;
  // height: 558px;
  @media (max-width: 768px) {
    // height: 730px;
  }
  @media (max-width: 426px) {
    // height: 628px;
    padding-bottom: 32px;
  }
`;
const BannerContainer = styled.div`
  padding-top: 170px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 526px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 425px) {
    padding-top: 97px;
    width: 278px;
    margin-right: auto;
  }
`;
const Container = styled.div`
  @media (min-width: 1200px) {
    width: 1050px;
    margin: auto;
  }
  @media (max-width: 1199px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 90%;
    margin: auto;
  }
`;
// const bounceAnimation = keyframes`${bounce}`;
// const pulseAnimation = keyframes`${tada}`;

const LeftWrapper = styled.div`
  width: 637px;
  @media (max-width: 1024px) {
    width: 580px;
  }
  @media (max-width: 1024px) {
    width: 580px;
  }
  @media (max-width: 991px) {
    width: 500px;
  }
  @media (max-width: 768.1px) {
    width: 90%;
    margin-right: auto;
  }
  @media (max-width: 526px) {
    width: 100%;
    margin: auto;
  }
`;
const RightWrapper = styled.div`
  width: 259px;
  img {
    :first-child {
      padding-bottom: 19px;
    }
  }
  @media (max-width: 991px) {
    width: 200px;
    img {
      width: 200px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const SmallRightWrapper = styled.div`
  width: 259px;
  img {
    :first-child {
      padding-bottom: 19px;
    }
  }
  @media (min-width: 768.1px) {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100px;
    margin-right: auto;
    img {
      width: 150px;
    }
  }
`;
const Float = keyframes`
	0% {
	
		transform: translatey(0px);
	}
	50% {
		
		transform: translatey(-20px);
	}
	100% {
	
		transform: translatey(0px);
	}
`;
const Grow = keyframes`
  from {
    transform: scale(0.4, 0.5);
  }
  to {
    transform: scale(1.5, 0.8);
  }
`;
const GlobeImg = styled.img`
  animation: ${Float} 2s ease-in-out infinite;
`;
const ShadowImg = styled.img`
  animation-name: ${Grow};
  animation-duration: 1s;
  animation-direction: alternate;
  // animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  animation-iteration-count: infinite;
`;
const PreText = styled.h5`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 6.5px;
`;
const Text = styled.h1`
  font-size: 56px;
  line-height: 64px;
  color: white;
  font-weight: bold;
  margin-bottom: 78px;
  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 56px;
  }
  @media (max-width: 991px) {
    font-size: 42px;
    line-height: 50px;
  }
  @media (max-width: 768px) {
    font-size: 46px;
    line-height: 52px;
  }
  @media (max-width: 425px) {
    font-size: 30px;
    line-height: 48px;
    margin-bottom: 28px;
  }
`;
const Button = styled.button`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: var(--pink);
  color: white;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: transparent;
`;
const ArrowDown = styled.div`
  padding-top: 41px;
  width: 39px;
  margin: auto;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 426px) {
    padding-top: 47px;
  }
`;

// Conversion Section

const ConversionWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding-top: 17px;
  @media (min-width: 1200px) {
    width: 1200px;
    margin: auto;
  }
  @media (max-width: 1199px) {
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 426px) {
    padding-top: 28px;
  }
`;
const LeftConversion = styled.div`
  background: url(${Cycle});
  background-size: cover;
  width: 560px;
  height: 350px;
  @media (max-width: 1199px) {
    width: 450px;
    height: 260px;
  }

  @media (max-width: 991px) {
    width: 380px;
    height: 230px;
  }
  @media (max-width: 850px) {
    width: 320px;
    height: 200px;
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;
const RightConversion = styled.div`
  width: 462px;
  margin-left: 116px;
  h5 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 6px;
    font-weight: 400;
  }
  h2 {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    span {
      color: var(--pink);
    }
  }
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 3px;
    font-weight: 400;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
    font-weight: 400;
  }
  @media (max-width: 1199px) {
    margin-left: 0px;
  }
  @media (max-width: 1024px) {
    width: 400px;
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media (max-width: 991px) {
    width: 380px;
    h2 {
      font-size: 30px;
      line-height: 36px;
    }
  }
  @media (max-width: 850px) {
    width: 350px;
    h2 {
      font-size: 28px;
      line-height: 35px;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const SmallRightConversion = styled.div`
width:100%;
margin:auto;
text-align:center;
padding-top:40px;
@media(min-width:768.1px){
    display:none;
}
@media(max-width:768px){
  padding-top:0px;
}
h5 {
    font-size: 20px;
    line-height: 28px;
    margin:auto;
    margin-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    width:380px;
    @media(max-width:426px){
        width:257px;
        font-size:14px;
        line-height:21px;
      margin-bottom:7px;
  }
  }
  
  
  h2 {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    width:480px;
    margin:auto;
    margin-bottom:15px;
    span {
      color: var(--pink);
    }
    @media(max-width:576px){
        width:400px;
        font-size:32px;
        line-height:40px;
    }
    @media(max-width:426px){
        width:301px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom:20px;
        
    }
`;
const SmallStat = styled.p`
  position: absolute;
  font-size: 9px;
  line-height: 20px;

  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 576px) {
    font-size: 7px;
  }
`;

// Slide in Section
const Example = styled.div`
  background: url(${Digital});
  background-size: cover;
  height: 350px;
  display: flex;
  position: relative;
  align-items: center;
  z-index: 0;

  @media (min-width: 1200px) {
    width: 1200px;
    margin: auto;
  }
  // @media (max-width: 991px) {
  //   background: url(${DigitalTwo});
  //   background-size: cover;
  // }
  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;
const SmallExample = styled.div`
  background: url(${SmallDigital});
  height: 350px;
  background-size: cover;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  @media (min-width: 576.1px) {
    display: none;
  }
`;
const UnderlayWrapper = styled.div`
  background: url(${Parallax});
  background-size: cover;
  width: 561px;
  height: 350px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  @media (max-width: 991px) {
    width: 500px;
    background-size: contain;
  }
  @media (max-width: 768px) {
    width: 100%;
    background-size: cover;
  }
`;
const SmallUnderlayWrapper = styled.div`
  background: #000000;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 165px;
  padding-top: 30px;
  padding-bottom: 30px;
`;
const ExampleText = styled.h3`
  margin-bottom: 0px;
  position: relative;
  z-index: 2;
  font-weight: 700;
  font-size: 46px;
  line-height: 54px;
  width: 423px;
  color: white;
  @media (max-width: 768px) {
    margin: auto;
    text-align: center;
  }
  @media (max-width: 576px) {
    margin-top: 170px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    width: 224px;
  }
`;
const ExampleButton = styled(Link)`
  width: 291px;
  border-radius: 10px;
  background-color: var(--lightBlue);
  color: white;
  height: 70px;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  z-index: 3;
  :hover {
    color: white;
  }
  @media (max-width: 768px) {
    margin: auto;
    margin-top: 10px;
    text-align: center;
  }
  @media (max-width: 576px) {
    width: 175px;
    height: 42px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 14px;
  }
`;
const VideoContainer = styled.div`
  @media (min-width: 1200px) {
    width: 1200px;
    margin: auto;
  }
  @media (max-width: 1199px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
`;
const VideoSection = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
  .whiteyt {
    width: 183px;
  }
`;
const SmallVideoSection = styled.div`
  background: url(${SmallVid});
  background-size: cover;
  height: 350px;
  text-align: center;
  position: relative;
  .whiteyt {
    visibility: hidden;
    padding-top: 30px;
    width: 183px;
  }
  @media (min-width: 768.1px) {
    display: none;
  }
`;
const UnderlayVid = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  margin-top: 165px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
const ThumbnailDivider = styled.div`
  background-color: var(--pink);
  height: 22px;
  margin-top: 15px;
  width: 268px;
  margin-left: auto;
`;
const LeftVideo = styled.div`
  width: 569px;
  img {
    width: 100%;
  }
  @media (max-width: 1199px) {
    width: 50%;
  }
`;
const RightVideo = styled.div`
  text-align: center;
  margin-left: 25px;
  img {
    text-align: center;
    margin-bottom: 24px;
  }
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 1024px) {
    width: 60%;
    padding-right: 25px;
  }
`;
const VideoHeading = styled.h3`
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  @media (max-width: 1199px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (max-width: 991px) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    color: white;
    padding-top: 133px;
    position: relative;
    z-index: 2;
  }
`;
const VideoSubHeading = styled.h6`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    color: white;
    z-index: 2;
    position: relative;
  }
  @media (max-width: 426px) {
    font-size: 14px;
    font-weight: 300;
  }
`;

const VideoButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #00aeef;
  color: white;
  border: transparent;
  padding: 20px 56px;
  font-weight: bold;
  font-size: 38px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 55px;
  img {
    padding-left: 19px;
    align-items: center;
    margin-bottom: 0px;
  }
  @media (max-width: 1024px) {
    margin-top: 25px;
  }
  @media (max-width: 768px) {
    padding: 10px 23px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
    position: relative;
    img {
      width: 40px;
    }
  }
`;
const ContactArea = styled.div`
  padding-top: 93px;
  padding-bottom: 62px;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SmallContact = styled.div`
  padding-top: 93px;
  padding-bottom: 62px;
  @media (min-width: 768.1px) {
    display: none;
  }
  @media (max-width: 768px) {
    width: 500px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 295px;
    margin: auto;
    padding-top: 50px;
    padding-bottom: 21px;
  }
`;
const LeftContact = styled.div`
  width: 350px;
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
    margin-bottom: 8px;
  }
  @media (max-width: 426px) {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const RightContact = styled.div`
  width: 574px;
  margin-left: auto;
  h2 {
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 18px;
    width: 100%;
  }
  h5 {
    width: 512px;
    margin: auto;
    font-size: 24px;
    line-height: 32px;
    padding-top: 18px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    h2 {
      font-size: 52px;
      line-height: 60px;
    }
    h5 {
      width: 500px;
    }
  }
  @media (max-width: 991px) {
    h2 {
      font-size: 40px;
      line-height: 48px;
      width: 400px;
      margin: auto;
    }
    h5 {
      width: 380px;
      font-size: 20px;
      line-height: 28px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    h2 {
      font-size: 50px;
      line-height: 58px;
      width: 100%;
    }
    h5 {
      width: 100%;
    }
  }
  @media (max-width: 426px) {
    h2 {
      font-weight: 400;
      font-size: 52px;
      line-height: 60px;
    }
    h5 {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const Center = styled.div`
  text-align: center;
`;
const SmallButton = styled.button`
  background-color: var(--pink);
  width: 166px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-weight: bold;
  border-color: transparent;
  color: white;
  margin: auto;
  margin-top: 55px;
`;
