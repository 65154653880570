import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import Featured from "../../assets/Featured.png";

export default function Post(props) {
  const currentPosts = { props };
  const media = currentPosts.props.post["_embedded"]["wp:featuredmedia"];

  const truncate = (str, length, ending) => {
    if (length == null) {
      length = 40;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  const truncateHeading = (str, length, ending) => {
    if (length == null) {
      length = 28;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <React.Fragment>
      <StyledLink to={`news/${currentPosts.props.post.id}`}>
        <Container>
          {media === undefined || !media ? (
            <Thumbnail>
              <React.Fragment>
                <Overlay></Overlay>
                <img src={Featured} alt="Post Thumbnail" style={{width: '100%', height: 'auto'}} />
              </React.Fragment>
            </Thumbnail>
          ) : (
            <ImageContainer>
              <React.Fragment>
                <Overlay>
                  <PostTitle>
                    {renderHTML(
                      truncateHeading(currentPosts.props.post.title.rendered)
                    )}
                  </PostTitle>
                  <PostExcerpt>
                    {renderHTML(
                      truncateHeading(currentPosts.props.post.excerpt.rendered)
                    )}
                  </PostExcerpt>
                </Overlay>
                  <img src={media[0]["source_url"]} alt="Post Thumbnail" style={{ width: '100%' }} />
              </React.Fragment>
            </ImageContainer>
          )}
        </Container>
        <SmallContainer>
          <Row>
            <LeftSection>
              <LeftImg>
                {media === undefined || !media ? (
                  <img src={Featured} alt="Post Thumbnail" style={{ width: '100%' }} />
                ) : (
                  <img src={media[0]["source_url"]} alt="Post Thumbnail" style={{ width: '100%' }} />
                )}
              </LeftImg>
            </LeftSection>

            <RightSection>
              <PostTitle>
                {renderHTML(
                  truncateHeading(currentPosts.props.post.title.rendered)
                )}
              </PostTitle>

              <PostExcerpt>
                {renderHTML(truncate(currentPosts.props.post.excerpt.rendered))}
              </PostExcerpt>
            </RightSection>
          </Row>
        </SmallContainer>
      </StyledLink>
    </React.Fragment>
  );
}
const StyledLink = styled(Link)`
  color: black;
  :hover {
    text-decoration: none;
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 185px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 13px;
`;
const Row = styled.div`
  display: flex;
  @media (max-width: 767.9px) {
  }
  @media (max-width: 426px) {
  }
`;

const ImageContainer = styled.div`
  width: 300px;

  overflow: hidden;
  position: relative;
  margin-bottom: 60px;
  img {
    height: 300px;
  }
  :hover {
    color: var(--pink);
  }
  @media (max-width: 1199px) {
    width: 270px;
    img {
      height: 270px;
    }
  }

  @media (max-width: 991px) {
    width: 300px;
    img {
      height: 300px;
    }
  }
`;
const Thumbnail = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
`;
const Container = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const SmallContainer = styled.div`
  @media (min-width: 768.1px) {
    display: none;
  }
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  @media (max-width: 767.9px) {
    width: 80%;
    margin: auto;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin: auto;
  }
`;

const LeftImg = styled.div`
  width: 250px;
  overflow: hidden;
  img {
    height: 250px;
    overflow: hidden;
    text-align: center;
  }

  @media (max-width: 576px) {
    width: 180px;

    overflow: hidden;
    img {
      height: 180px;
      text-align: left;
    }
  }
  @media (max-width: 426px) {
    width: 100%;
    height: 125px;
  }
`;

const LeftSection = styled.div`
  width: 250px;
  margin-right: 33px;

  @media (max-width: 426px) {
    overflow: hidden;
    margin-right: 33px;
  }
`;
const RightSection = styled.div`
  width: 316px;
  @media (max-width: 767.9px) {
    width: 400px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const PostTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  @media (min-width: 768.1px) {
    color: white;
  }
  :hover {
    color: pink;
  }
`;
const PostExcerpt = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  :hover {
    color: pink;
  }
  @media (max-width: 767.9px) {
    flex-direction: column;
    line-height: 24px;
  }
  @media (min-width: 768.1px) {
    font-weight: 300;
    color: white;
    width: 200px;
  }
`;
