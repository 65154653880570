import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { HashLink as Linker } from "react-router-hash-link";
import animateScrollTo from "animated-scroll-to";
export default function Footer() {
    // const copyDate = new Date().getFullYear();
    const ScrollToContact = () => {
        animateScrollTo(document.querySelector(".contactform"));
      };
      const ScrollToTop = () => {
        animateScrollTo(0);
      };
    return (
        <Wrapper>
            <Container>
            <FooterContent>
                <Copyright>
                LogiLab is a registered trademark and product by SANKYU, Inc. | <Link to ="/partner" onClick={()=>{ScrollToTop()}}>Information </Link> | <Linker to ="/#contactus" onClick={()=>{ScrollToContact()}}>Contact Us</Linker>
                </Copyright>
                
            </FooterContent>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div `
background-color:var(--darkAsh);
height:60px;
position:relative;
@media(max-width:576px){
    height:105px;
}
@media(max-width:425px){
    height:105px;
   
}
`
const Container = styled.div `
width:95%;
margin:auto;
@media(min-width:1200px){
    width:1200px;
    margin:auto;
}
@media(max-width:768px){
    width:100%;
    padding-left:15px;
    padding-right:15px;
}

`
const Copyright = styled.div `
color:white;
font-size: 16px;
line-height: 24px;
font-weight:300;
font-family: Open Sans;
a{
    font-weight:300;
    color:white;
}
@media(max-width:991px){
    font-size:16px;
    
}
@media(max-width:768px){
    font-size:12px;
    
}
@media(max-width:576px){
    font-size:17px;
   line-height:21px;
}
@media(max-width:360px){
    font-size:16px;
}
`
const FooterContent = styled.div `
display:flex;
align-items:center;
height:60px;
@media(min-width:1200px){
    padding-left:45px;
    padding-right:45px;
}
@media (min-width: 1024px) and (max-width: 1199px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 768px) and (max-width: 1023.9px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 767.9px) {
    padding-left: 20px;
    padding-right: 20px;
  }
@media(max-width:576px){
    flex-direction:column;
    justify-content:center;
    height:105px;
}
@media(max-width:425px){
    height:105px;

}
`

