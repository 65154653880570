import React, { Component } from "react";
import styled from "styled-components";
import Featured from "./Featured";
import PostList from "./PostList";
import animateScrollTo from "animated-scroll-to";
import bannerimage from '../../assets/bg-6.png';



export default class News extends Component {
  componentDidMount = () => {
    animateScrollTo(0);
  };
  render() {
    return (
      <React.Fragment>
      <BannerSection>
        <Container>
        <h2>NEWS</h2>
        </Container>
      </BannerSection>
      <Featured/>
        <Content>
        </Content>
        <PostList />
      </React.Fragment>
    );
  }
}
const BannerSection = styled.div `
@media(min-width:576.1px){
  background:url(${bannerimage});
  background-size:cover;
  height:220px;
  display:flex;
  align-items:center;
}
@media(max-width:576px){
  background:url(${bannerimage});
  background-size:cover;
height:220px;
display:flex;
align-items:center;
}
`
const Container = styled.div `
width:90%;
margin:auto;
@media(min-width:1200px){
  width:1150px;
  margin:auto;
}
h2{
  margin-bottom:0px;
  color:white;
  font-weight: bold;
font-size: 38px;
line-height: 46px;
padding-top:70px;
}
@media(max-width:1199px){
  width:95%;
  margin:auto;
}
@media(max-width:1024px){
  width:90%;
  margin:auto;
}
@media(max-width:991px){
  width:100%;
  padding-left:50px;

}
@media(max-width:768px){
  width:90%;
  padding-left:0px;
  
}

`


const Content = styled.div`
  @media (min-width: 1200px) {
    width: 752px;
    margin: auto;
  }
  @media only screen and (max-width: 1199.9px) and (min-width: 769px) {
    width: 720px;
    margin: auto;
  }
  @media only screen and (max-width: 768.9px) and (min-width: 375px) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin: auto;
  }
`;
