import React from 'react';
import {Switch, Route} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Home from './Components/Home/Home';
import Partner from './Components/PartnerPage/Partner';
import Profile from './Components/Profile/Profile';
import News from "./Components/Blog/Blog";
import VideoList from './Components/Videolibrary/VideoList';
import BlogPostDetails from "./Components/Blog/BlogPostDetails";

import Header from './Components/Header';
import './App.css';

import Footer from './Components/Footer';
import {AppProvider} from './Context';

function App() {
  React.useEffect(() => {
    TagManager.initialize({gtmId: 'GTM-W6FBLKD'});
  }, []);
  return (
    <AppProvider>
    <div className="App">
    <Header/>
     <Switch>
      <Route component ={Home} exact path ="/"/>
      <Route component ={Partner} exact path ="/partner"/>
      <Route component ={Profile} exact path ="/profile"/>
      <Route exact component={News} path="/news"/>
      <Route exact path="/news/:id" component={BlogPostDetails} />
      <Route exact path="/videolibrary" component={VideoList} />
      <Route exact path="/webinar" component={VideoList} />
     </Switch>
     <Footer/>
    </div>
  </AppProvider>
  );
}

export default App;

