import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Aos from "aos";
import { HashLink as Linker } from "react-router-hash-link";
import "aos/dist/aos.css";
import Daisuke from "../../assets/daisuke.png";
import Nariko from "../../assets/nariko.png";
import Kenta from "../../assets/kenta.png";
import Roberts from "../../assets/roberts.png";
import Tomu from "../../assets/tomu.png";
import close from "../../assets/times-close.svg";
import standagelogo from "../../assets/standage.svg";
import lozilogo from "../../assets/Lozi.svg";
import andlocallogo from "../../assets/and-local.svg";
import sankyu from "../../assets/sankyu.svg";
import arrow from "../../assets/Arrow.png";
import arrowUp from "../../assets/ArrowUp.png";
import animateScrollTo from "animated-scroll-to";
export default function Service() {
  const [hoveredProfile, setHoveredProfile] = useState(null);
  const [profileIndex, setProfileIndex] = useState(null);

  useEffect(() => {
    Aos.init(
      {
        duration: 1200,
      },
      []
    );
  });
  useEffect(() => {
    animateScrollTo(0);
  }, []);
  const ScrollToContact = () => {
    animateScrollTo(document.querySelector(".contactform"));
  };
  const profiles = [{
    name: "野田 大輔",
    company: "山九株式会社",
    department: "LS営業部",
    image: Daisuke,
    logo: sankyu,
    introduction: "１９９５年に、山九株式会社に入社。１９９８年から7年間香港に駐在し、国際調達物流「ＳＰ＠ＣＦＳ」を企画開発運営。帰国後、小口貨物の国際輸送サービス「SBY」を企画。その後も「ロジスティクス・ファイナンス事業」の立案など、新規案件の企画開発に従事。"
  }, {
    name: "中村 成道子",
    company: "山九株式会社",
    department: "LS営業部",
    image: Nariko,
    logo: sankyu,
    introduction: "山九株式会社で15年以上、多くの新規物流シ ステムの企画・開発に従事。「物流・支払い代行システム」 では特許も取得。小口貨物の国際輸送サービス「SBY」を企画開発。 スタートアップ企業の新規開拓や調査、商品企画など、物流業界の革新に精力的。"
  }, {
    name: "大森健太",
    company: "株式会社STANDAGE",
    department: "取締役副社長",
    image: Kenta,
    logo: standagelogo,
    introduction: "東京大学卒業後、総合商社の伊藤忠商事株式会社へ入社。 主に医薬品やヘルスケア製品の貿易、海外企業へのM&Aに従事。 BtoB貿易におけるデジタルシフトの遅さを痛感すると共に、本分野のDXの可能性を強く確信し、2017年に伊藤忠商事の先輩である足立彰紀（現STANDAGE CEO）と共に株式会社STANDAGEを共同創業。<br />Sales/MarketingのTOPとして、ユーザー並びに協業パートナーの開拓、事業化を日々推し進めている。"
  }, {
    name: "Martin Roberts<br />渡邊 重雄",
    company: "株式会社LOZI",
    department: "代表取締役",
    image: Roberts,
    logo: lozilogo,
    introduction: "株式会社新東通信で新規事業開発リーダーを務め、日経新聞クラウドファンディング PF 「未来ショッピング」や、CCHANNEL女性アパレル逆越境EC「Isn’t She」を 立ち上げる。2018年に、株式会社LOZIを創業し、サプライチェーンを分断することなくモノの動きを可視化する 「SmartBarcode®」 事業の企画開発を行う。"
  }, {
    name: "面谷斗夢",
    company: "株式会社アンドローカル",
    department: "取締役",
    image: Tomu,
    logo: andlocallogo,
    introduction: "２００８年からNTTグループにて電子マネー事業開発に従事。２０１８年にスタートアップ企業ZEROBILLBANKへ参画。多くの大企業に向けた新規事業開発に携わり、革新的なビジネスモデルを構築。２０２１年には、その経験と情熱を活かし、株式会社アンドローカルを起業。新規事業開発や地方課題解決、スマート農業、AIなど、幅広い分野で活動を行う。"
  }]

  const closeProfileDetails = () => {
    setProfileIndex(null);
  }

  const userInfo = (profile) => (
    <React.Fragment>
      <h5>【ロジラボ共同設立】</h5>
      <h2 dangerouslySetInnerHTML={{ __html: profile.name }} />
      <p>
        {profile.company} <br />
        {profile.department}
      </p>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Container>
        <BannerSection>
          <LeftBanner>
            <h4>COMMUNITY PARTNERS</h4>
            <h2>参加企業</h2>
            <SmallDivider />
            <p>
              貿易・物流業界における経験豊富なロジラボ加盟メンバーを御紹介します。
              スタアトアップ企業・大企業関わらず、ロジラボでは企業間の垣根を越えて、
              貿易・物流業界で本当に必要とされるサービスを構築して参ります。
              <br /> <br />
              ロジラボでは革新的なサービスを共に構築できるチームメンバーを常に求めています。
              物流のプロ、貿易のプロ、ブロックチェーンのプロ、トレーサビリティのプロ・・・
              ロジラボには様々な領域のプロフェッショナルがいます。私達と共に、世界にインパクトを与えるサービスを創りましょう！
            </p>

            <Divider
              data-aos="slide-right"
              style={{ transitionDuration: "2.4s" }}
            />
          </LeftBanner>
          <RightBanner>
            <RightButton to="/#contactform" onClick={ScrollToContact}>
              パートナー募集
            </RightButton>
          </RightBanner>
        </BannerSection>
        <ProfileWrapper>
          {profiles.map((profile, index) => (
            <div className="profile-container" >
              <div 
                key={index} 
                onMouseEnter={() => setHoveredProfile(index)}
                onMouseLeave={() => setHoveredProfile(null)}
                onClick={() => setProfileIndex(index)}
              >
                <img src={profile.image} alt={`${profile.name}の写真`} className="profile-image" />
                {hoveredProfile === index && (
                  <div className="profile-preview">
                    <img src={profile.logo} alt={`${profile.company}`} style={{width: "79px", height: "20px", marginBottom: "16px"}} />
                    {userInfo(profile)}
                    <OpenDetails onClick={() => setProfileIndex(index)} className="control-icon">
                      <img src={arrow} alt="open icon" style={{width: "15px", height: "100%"}} />
                    </OpenDetails>
                  </div>
                )}
              </div>
              {
                index === profileIndex && (
                  <div className="profile-details">
                    <Logos>
                      <img src={profile.logo} alt={`${profile.company}`} />
                      <img src={close} alt="close icon" onClick={() => closeProfileDetails()} style={{cursor: "pointer"}} />
                    </Logos>
                    {userInfo(profile)}
                    <p>{profile.introduction}</p>
                    <OpenDetails onClick={() => closeProfileDetails()}>
                      <img src={arrowUp} alt="close icon" style={{ cursor: "pointer" }} />
                    </OpenDetails>
                  </div>
                )
              }
            </div>
          ))}
        </ProfileWrapper>
      </Container>
    </React.Fragment>
  );
}

const Container = styled.div`
  margin-bottom: 480px !important;
  @media (min-width: 1200px) {
    width: 942px;
    margin: auto;
  }
  @media (max-width: 1199px) {
    width: 942px;
    margin: auto;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 95%;
    margin-bottom: 0px !important;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 321px;
    margin: auto;
  }
`;

const BannerSection = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    width: 520px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: 426px) {
    width: 321px;
    margin: auto;
  }
`;
const LeftBanner = styled.div`
  width: 508px;
  padding-top: 112px;
  h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 9px;
  }
  h2 {
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 16px;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    text-align: justify;
    margin-bottom: 20px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    p {
      margin-top: 23px;
      margin-bottom: 15px;
    }
  }
`;
const RightBanner = styled.div`
text-align-left;
margin-left:auto;
margin-bottom: 68px;
@media(max-width:991px){
  margin-right:auto;
  margin-left:0px;
  margin-top:15px;
  margin-bottom: 0px;
}

`;
const RightButton = styled(Linker)`
  background-color: var(--lightBlue);
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  border-radius: 10px;
  padding: 6px 43px;
  text-align: center;
  border-color: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;

  :hover {
    color: white;
  }
`;
const Divider = styled.div`
  height: 22px;
  background-color: var(--pink);
  width: 268px;
  @media (max-width: 991px) {
    display: none;
  }
`;
const SmallDivider = styled.div`
  height: 22px;
  background-color: var(--pink);
  width: 268px;
  @media (min-width: 991.1px) {
    display: none;
  }
`;

const ProfileWrapper = styled.div`
  margin: 57px auto;
  color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
     margin: 57px 10%;
    grid-template-columns: 150px 150px;
    justify-content: space-around;
  }
  .profile-image {
    @media (max-width: 576px) {
      width: 150px;
      height: 150px;
    }
  }
  .profile-container {
    position: relative;
    width: 300px;
  }
  .profile-preview {
    height: 300px;
    width: 300px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background: rgba(0, 174, 239, 0.75);
    padding: 24px;
    cursor: zoom-in;
    @media (max-width: 576px) {
      padding: 12px;
      width: 150px;
      height: 150px;
    }
  }
  .profile-details {
    width: 100%;
    background: rgb(0, 174, 239);
    z-index: 2;
    position: absolute;
    top: 324px;
    left: 0;
    padding: 24px;
      @media (max-width: 768px) {
      display: none;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
  h2 {
    margin: 10px auto;
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    .control-icon {
      display: none;
    }
  }
  @media (max-width: 576px) {
    padding: 23px 11px;
    h2 {
      font-size: 16px;
      line-height: 24px;
      margin: 0 auto;
    }
    h5 {
      font-size: 10px;
      line-height: 20px;
      margin: 0 auto;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      margin: 0 auto;
    }
  }
`;

const Logos = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  img {
    width: 79px;
    height: 20px;
    @media (max-width: 576px) {
      width: 40px;
      height: 100%;
    }
  }
  img:last-child {
    width: 15px;
    height: 15px;
  }
`;

const Float = keyframes`
	0% {
	
		transform: translatey(0px);
	}
	50% {
		
		transform: translatey(-10px);
	}
	100% {
	
		transform: translatey(0px);
	}
`;
const OpenDetails = styled.div`
  animation: ${Float} 2s ease-in-out infinite;
  img {
    cursor: pointer;
  }
`;