import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import Featured from "../../assets/Featured.png";
import animateScrollTo from "animated-scroll-to";

export default function RelatedPost(props) {
  const currentPosts = { props };
  const media = currentPosts.props.post["_embedded"]["wp:featuredmedia"];
  const truncate = (str, length, ending) => {
    if (length == null) {
      length = 40;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  const ScrollToTop = () => {
    animateScrollTo(0);
  };
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
    console.log("page to reload");
  };
  const truncateHeading = (str, length, ending) => {
    if (length == null) {
      length = 28;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <React.Fragment>
      <StyledLink
        to={`${currentPosts.props.post.id}`}
        onClick={() => {
          refreshPage();
          ScrollToTop();
        }}
      >
        <Container>
          <Row>
            <LeftSection>
              <LeftImg>
                {media === undefined || !media ? (
                  <img src={Featured} alt="Post Thumbnail" />
                ) : (
                  <img src={media[0]["source_url"]} alt="Post Thumbnail" />
                )}
              </LeftImg>
            </LeftSection>

            <RightSection>
              <PostTitle>
                {renderHTML(
                  truncateHeading(currentPosts.props.post.title.rendered)
                )}
              </PostTitle>

              <PostExcerpt>
                {renderHTML(truncate(currentPosts.props.post.excerpt.rendered))}
              </PostExcerpt>
            </RightSection>
          </Row>
        </Container>
      </StyledLink>
    </React.Fragment>
  );
}
const StyledLink = styled(Link)`
  color: black;
  :hover {
    text-decoration: none;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Container = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin: auto;
    margin-bottom: 29px;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin: auto;
  }
`;

const LeftImg = styled.div`
  width: 125px;
  overflow: hidden;
  img {
    height: 125px;
    overflow: hidden;
    text-align: center;
  }

  @media (max-width: 991px) {
    width: 200px;

    overflow: hidden;
    img {
      height: 200px;
      text-align: left;
    }
  }
  @media (max-width: 576px) {
    width: 125px;

    overflow: hidden;
    img {
      height: 125px;
      text-align: left;
    }
  }
  @media (max-width: 426px) {
    width: 100%;
    height: 125px;
  }
`;

const LeftSection = styled.div`
  width: 125px;
  margin-right: 33px;

  @media (max-width: 1199px) {
    margin-right: 10px;
  }
  @media (max-width: 991px) {
    width: 200px;
    margin-right: 33px;
  }
  @media (max-width: 426px) {
    width: 100%;
    overflow: hidden;
    margin-right: 33px;
  }
`;
const RightSection = styled.div`
  width: 154px;
  @media (max-width: 991px) {
    width: 200px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const PostTitle = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 991px) {
    padding-top: 10px;
    font-size: 22px;
    line-height: 31px;
    font-weight: bold;
  }
  @media (max-width: 575.9px) {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
`;
const PostExcerpt = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  @media (max-width: 767.9px) {
    line-height: 24px;
  }
`;
